import { parseISO,formatDistanceToNow } from 'date-fns';

export const RelativeDate = ({ date }) => {

    console.log("Date" , date);
    const parsedDate = parseISO(date);
  
    const relativeDate = formatDistanceToNow(parsedDate, { addSuffix: true });

    return relativeDate;
  };


const Glossary = {
  'GEM': 'Gros électroménager',
  'PEM': 'Petit électroménager',
  'MOYEN': '★ ★',
  'COMPLEX': '★ ★ ★',
  'SIMPLE': '★',
  'MAINTENANCE' : 'Entretien',

  // Ajoutez d'autres correspondances au besoin
};

/*
fridge
freezer
dishwasher
washer
dryer
stove
hob
hood
Coven
microwave
drink_maker
toaster
cooking_robot
vaccum
heat_ac
other_appliance
*/
const Geev = {
  'Lave_Linge': 'washer',
  'Réfrigérateur' : 'fridge',
  'Aspirateur'  : 'vaccum',
  'Lave-Vaiselle' : 'washer',
  '' : 'other_appliance'
  // Ajoutez d'autres correspondances au besoin
};

  export const capitalizeFirstLetter = (text)  => {
    console.log("string to capitalize ", text);
    let res = text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    console.log("string capitalized ", res);
     return res;
  }

  // Fonction glossaire
  const getLabel = (text) => {
      // Recherchez le texte dans le glossaire, s'il existe, retournez la correspondance, sinon retournez le texte d'origine
      return Glossary[text] || text;
      };

  // Fonction glossaire
  export const getGeevCateg = (text) => {
    // Recherchez le texte dans le glossaire, s'il existe, retournez la correspondance, sinon retournez le texte d'origine
    return Geev[text] || text;
    };


export default getLabel;