import React from "react";
import getLabel from "../../services/glossary.service";

const ViewEvent = ({event}) => {
    if (!event) return null;
    return (
    <div key={event.id} className="read-notification"
                      style={{ 
                        padding: "0.5rem",
                        borderBottom: "1px solid lightgray", // Add bottom border"
                        position: 'relative', display: 'flex'
                      }} >
                      <div className="icon-column">
                          <div >
                          {event.type === 'MAINTENANCE' && 
                                      <div className="card-caroussel light-green-background">
                                          <img 
                                            src={process.env.PUBLIC_URL + '/icons/Maintenance.svg'} 
                                            style={{ width: 'auto', height: '2rem', verticalAlign: 'middle' }} alt="maintenance" />
                                      </div>
                                    }
                          </div>
                      </div>
                      <div className="ms-3 d-flex flex-column justify-content-center flex-grow-1">
                          <div className="d-flex justify-content-between"> 
                            <span style={{ fontSize: '0.75rem', fontWeight: '600', textTransform: 'uppercase'}}>{getLabel(event.type)}</span>
                            <span style={{ fontSize: '0.65rem', fontWeight: '400' }}>{new Date(event.dt_realisation).toLocaleDateString()}</span>
                          </div>
                          
                          <div className="mt-2" style={{ fontSize: '0.75rem', fontWeight: '400' }}>
                            {event.title.charAt(0).toUpperCase() + event.title.slice(1).toLowerCase()}
                            </div> 
                      </div>
                      
                    </div>
    );
};

export default ViewEvent; 