import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap'; 
import getLabel from "../../services/glossary.service";

import notifServiceInstance from "../../services/notif.service";


import { Navigate, useNavigate } from "react-router-dom";

import "../../css/notif.css";

const ViewNotif = () => {

  const [notifs, setNotifs] = useState(null);

  const navigate = useNavigate();


  const [selectedNotif, setSelectedNotif] = useState(null); // État pour la notification sélectionnée


  // Fonction pour ouvrir le modal et marquer la notification comme lue
  const handleNotificationClick = (notif) => {
    if (notif.status === 'ACTIF'){
      notifServiceInstance.readNotif(notif.id)
      .then(() => {
        // Mettre à jour le statut dans l'état local des notifications sans recharger la page
        const updatedNotifs = notifs.map((n) => {
          if (n.id === notif.id) {
            return { ...n, status: 'READ' }; // Modifier le statut localement
          }
          return n;
        });
        setNotifs(updatedNotifs); // Mettre à jour les notifications dans l'état local
      })
      .catch((error) => {
        console.error('Erreur lors de la mise à jour de la notification:', error);
      });
    }
    //updateNotificationStatus(notif.id, 'READ');
    setSelectedNotif(notif); // Définit la notification sélectionnée
  };


  // Fonction pour fermer le modal
  const handleCloseModal = () => {
    setSelectedNotif(null); // Réinitialise la notification sélectionnée
  };


  useEffect(() => {

    const fetchNotif = async () => {
      try {
          const data = await notifServiceInstance.getNotif();  // Récupérer les univers
          setNotifs(data);  // Mettre à jour l'état avec les univers
          console.log('notif! ', data);
      } catch (error) {
          console.error("Erreur lors de la récupération des univers:", error);
      }
    };

    fetchNotif();  // Appel de la fonction asynchrone pour récupérer les univers

  }, []);


  return (
    <div className="mt-4">
          <div>
            <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button onClick={() => navigate(-1)} style={{ position: 'absolute', left: '0', border: 'none', background: 'none', cursor: 'pointer', fontSize: '24px' }}>
                &lt;
              </button>
              <h3 style={{ margin: '0', textAlign: 'center', fontSize: '1.25rem', fontWeight: '600' }}>Mes Notifications</h3>
            </div>
          </div>
        {notifs && notifs.length > 0 && ( 
         <div className="mt-4">
                 {notifs.map((notif) => (    
                    <div 
                      onClick={() => handleNotificationClick(notif)} 
                      key={notif.id} 
                      className={`${notif.status === 'ACTIF' ? 'unread-notification' : 'read-notification'}`}
                      style={{ 
                        borderBottom: "1px solid lightgray" // Add bottom border"
                      }}>
                      <div style={{ 
                        padding: "10px"
                      }}>
                          {/* Première ligne: point rouge si le statut est 'ACTIF', aligné à droite */}
                          <div className="d-flex justify-content-end align-items-center">
                            {notif.status === 'ACTIF' && (
                            <div
                              className="mb-2"
                              style={{
                                width: '0.5rem',
                                height: '0.5rem',
                                backgroundColor: '#C03744',
                                borderRadius: '50%',
                              }}
                            ></div>)}
                          </div>
                        
                          {/* Deuxième ligne: icône à gauche, et colonne du titre, date et détail */}
                          <div className="d-flex align-items-center" style={{ position: 'relative', display: 'flex' }}>
                            {/* Colonne de l'icône à gauche */}
                            <div className="icon-column">
                              {notif.type === 'MAINTENANCE' && 
                                <div className={`card-caroussel ${notif.status === 'ACTIF' ? 'white-background' : 'light-green-background'}`}>
                                    <img 
                                      src={process.env.PUBLIC_URL + '/icons/Maintenance.svg'} 
                                      style={{ width: 'auto', height: '2rem', verticalAlign: 'middle' }} alt="maintenance" />
                                </div>
                              }
                              {notif.type === 'CHALLENGE' && 
                                <div className={`card-caroussel ${notif.status === 'ACTIF' ? 'white-background' : 'light-green-background'}`}>
                                  <img 
                                    src={process.env.PUBLIC_URL + '/icons/Heart.svg'} 
                                    style={{ width: 'auto', height: '2rem', verticalAlign: 'middle' }} alt="challenge" />
                                </div>
                              }
                            </div>
                        
                            {/* Colonne du titre, date et détail */}
                            <div className="ms-3 d-flex flex-column justify-content-center flex-grow-1">
                              <div className="d-flex justify-content-between">
                                {/* Titre */}
                                <span style={{ fontSize: '0.75rem', fontWeight: '600', textTransform: 'uppercase'}}>
                                  {getLabel(notif.type)}
                                </span>
                        
                               {notif.event.dt_realisation && 
                                (<span style={{ fontSize: '0.65rem', fontWeight: '400' }}>
                                  {new Date(notif.event.dt_realisation).toLocaleDateString()}
                                </span>)}

                              </div>
                        
                              <span className="mt-2" style={{ fontSize: '0.75rem', fontWeight: '400' }}>
                                {notif.title.charAt(0).toUpperCase() + notif.title.slice(1).toLowerCase()}
                              </span>
                            </div>
                          </div>
                      </div>
                    </div>
                  ))}

                  {/* Modal */}
                  {selectedNotif && (
                    <Modal show={true} onHide={handleCloseModal} centered>
                      <Modal.Header closeButton>
                        <Modal.Title >
                           <span style={{ fontSize: '1rem', fontWeight: '500'}}>
                            Notification pour le {new Date(selectedNotif.event.dt_realisation).toLocaleDateString()}
                            </span>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body style={{alignContent :"center", fontSize: '0.75rem', fontWeight: '500'}}>
                        <div >
                          {selectedNotif.title.charAt(0).toUpperCase() + selectedNotif.title.slice(1).toLowerCase()}
                        </div>
                        <div className="mt-1">
                          Notes : {selectedNotif.event.note}
                        </div>
                        <div className="mt-3">
                        Vous aurez besoin de: 
                          {
                            selectedNotif.event.maintenanceCard.agentlist.map((produit) => (
                            <div key={produit.id} >
                               - {produit.name}
                            </div>
                          ))}
                        </div>
                        {selectedNotif.type === 'MAINTENANCE' && 
                          <div className="mt-3">
                           <p>Il vous manque un produit pour votre entretien</p>
                           <Button variant="primary" onClick={handleCloseModal}>
                              VOIR LES PRODUITS
                           </Button>
                          </div>}
                      </Modal.Body>
                    </Modal>
                  )}
        </div>
      )}
    </div>
  );
};

export default ViewNotif;