import { EditIcon } from "./Icon";
import React, { useState, useEffect } from 'react';


const EditableField = ({ label, field, value, isEditable, onChange, handleEditClick }) => (
  <div style={{ marginBottom: '10px' }}>
    <div className="text-medium" style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
      <label htmlFor={field}>{label}</label>
    </div>
    <div className="text-small" style={{ position: 'relative' }}>
      {field === "note" ? (
        <textarea
          id={field}
          aria-label={label}
          value={value || ""}
          placeholder={value || ""}
          disabled={!isEditable}
          onChange={(e) => onChange(e.target.value)}
          style={{
            width: '100%',
            padding: '20px',
            height: '12rem',
            cursor: isEditable ? 'text' : 'pointer',
            resize: 'none'
          }}
        />
      ) : (
        <input
          id={field}
          type="text"
          aria-label={label}
          value={value || ""}
          placeholder={value || ""}
          disabled={!isEditable}
          onChange={(e) => onChange(e.target.value)}
          style={{ width: '100%', paddingRight: '2rem', cursor: isEditable ? 'text' : 'pointer' }}
        />
      )}

      <EditIcon
        onClick={() => handleEditClick(field)}
        role="button"
        tabIndex="0"
        aria-label={`Edit ${label}`}
        onKeyDown={(e) => e.key === 'Enter' && handleEditClick(field)}
      />
    </div>
  </div>
);


const FieldText = ({ label, value, onChange, required}) => (
    <div style={{ marginTop: '10px', textAlign: 'start' }}>
      <label htmlFor={label} className="text-medium">
        {label}
      </label>
      <input
        id={label}
        type='text'
        value={value}
        onChange={onChange}
        className="text-small"
        style={{
          width: '100%',
          padding: '5px',
          marginTop: '5px',
          border: '1px solid #ccc',
          borderRadius: '5px',
        }}
        aria-label={label}
        required={required}
      />
    </div>
  );

  const FieldTextArea = ({ label, value, onChange, required}) => {


    const [rows, setRows] = useState(1);

    useEffect(() => {
      // Ajuster le nombre de lignes en fonction de la longueur de `value`
      const charsPerLine = 55; // Nombre approximatif de caractères par ligne, ajustez selon votre `textarea`
      const lineCount = Math.ceil(value.length / charsPerLine);
      setRows(Math.min(Math.max(lineCount, 1), 10)); // Limite entre 1 et 10 lignes
   }, [value]);

    return (
    <div style={{ marginTop: '10px', textAlign: 'start' }}>
      <label htmlFor={label} className="text-medium">
        {label}
      </label>
      <textarea
        id={label}
        type='text'
        value={value}
        onChange={onChange}
        className="text-small"
        style={{
          width: '100%',
          padding: '5px',
          marginTop: '5px',
          border: '1px solid #ccc',
          borderRadius: '5px',
        }}
        aria-label={label}
        required={required}
        rows={rows}
      />
    </div>
  );
};


export default EditableField;
export { FieldText, FieldTextArea };