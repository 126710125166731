import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ApplianceListView = ({ listToDisplay, handleClicktoDetail }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [voirPlus, setVoirPlus] = useState(false);

    // Adapter la structure de userProductList pour qu'elle corresponde à refProductList
  const adaptedUserProductList = listToDisplay.map(product => ({
    prodName: product.prodName || product.prod_name, // Prendre prodName ou prod_name s'il existe
    prod_name: product.prod_name,
    pict: product.pict, // Garder les autres propriétés telles quelles
    name: product.name,
    id: product.id // Garder l'id si nécessaire
  }));


  // Filtrer la liste basée sur le terme de recherche
  const filteredProducts = Array.isArray(adaptedUserProductList) 
    ? adaptedUserProductList.filter((product) => product.prodName.toLowerCase().includes(searchTerm.toLowerCase()))
    : [];

  // Déterminer les produits à afficher en fonction de voirPlus et searchTerm
  const produitsAAfficher = voirPlus || searchTerm ? filteredProducts : filteredProducts.slice(0, 5);

  // Gestionnaire pour la recherche
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setVoirPlus(false); // Réinitialiser voirPlus à chaque nouvelle recherche
  };

  // Gestionnaire pour afficher plus ou moins
  const handleVoirPlus = (bool) => {
    if (bool) setSearchTerm('');
    setVoirPlus(bool);
  };

  return (
    <div className="product-container mt-4 mb-4">
      {/* Champ de recherche */}
      <div className="search-bar mb-4">
        <label htmlFor="search-input" className="visually-hidden">Rechercher un produit</label>
        <input
          type="text"
          id="search-input"
          placeholder="Rechercher un produit..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="form-control"
          aria-label="Rechercher un produit"
        />
      </div>

      {/* Liste des produits */}
      <div className="d-flex row gx-3" role="list">
        {produitsAAfficher.length>0 && produitsAAfficher.map((product) => (
          <div key={product.id} className="col-6 col-md-4 col-lg-3 product-link mb-3" role="listitem">
            <div 
              onClick={() => handleClicktoDetail(product)} 
              className="product-background text-center"
              style={{ height: "100%",border: 'none'}}
              aria-label={`Ajouter ${product.prodName}`}
            > <div className="mb-2" style={{ height: '120px',display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                <img 
                src={product.pict} 
                alt={product.prodName} 
                className="img-fluid d-block mx-auto"
                style={{ maxHeight:'120px',objectFit: 'cover' }}/>
             </div>
              <span className="text-medium">{product.prodName}</span>
              {product.name && <div className="text-small">{product.name}</div>}
            </div>
          </div>
        ))}
      </div>

      {/* Boutons "Voir plus" / "Voir moins" */}
      {!voirPlus && (
        <button 
          className="btn btn-primary mb-10" 
          onClick={() => handleVoirPlus(true)}
          aria-expanded={voirPlus}
          aria-controls="product-list"
        >
          Voir plus
        </button>
      )}
      {voirPlus && (
        <button 
          className="btn btn-primary mb-10" 
          onClick={() => handleVoirPlus(false)}
          aria-expanded={voirPlus}
          aria-controls="product-list"
        >
          Voir Moins
        </button>
      )}
    </div>
  );
};

// Validation des props avec PropTypes
ApplianceListView.propTypes = {
  listToDisplay: PropTypes.array.isRequired,
  handleClicktoDetail: PropTypes.func.isRequired
};

export default ApplianceListView;
