import React, {useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/entretienproduct.css";

import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap"; 


import Navigation from "../navigation";
import Header from "../common/Header";

import { getGeevCateg} from "../../services/glossary.service";

const Give = () => {
    const location = useLocation();
    const { state } = location|| {}; // S'assurer que state est un objet si location.state est null;
    const { product } = state|| {}; // S'assurer que state n'est pas null avant de déstructurer
    console.log("appareil reçu ",product);

    const navigate = useNavigate();
  
  useEffect(() => {
    console.log('Dans inventaire des produits');
    document.body.style.backgroundColor = '#ffffff';
  }, []); // Le tableau vide en second paramètre signifie que useEffect s'exécutera une fois après le montage initial

  const handleClicktoGive = (product) => {
    const categ=getGeevCateg(product.prod_name);
    window.open('https://www.geev.com/fr/recherche/objets?categories='+categ, '_blank');
  };

  const handleClicktoEcosystem = () => {
    // Rediriger vers la page CardDetail et passer l'objet maintenanceCard
    //?product=Aspirateur&location=Paris%252C%2BFrance&lat=48.856614&lon=2.3522219
    const location ='location=Paris%252C%2BFrance&lat=48.856614&lon=2.3522219';
    const categ= 'product='+product.prod_name;
    window.open(' https://www.ecosystem.eco/donner-recycler/?'+categ+'&'+location, '_blank');
  };

  
  return (
    <div className="mb-4">
    <Header/>  
      <div className="container mt-4">
          <Navigation/>
            <div className="justify-content-center"> 
                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <button onClick={() => navigate(-1)} style={{ position: 'absolute', left: '0', border: 'none', background: 'none', cursor: 'pointer', fontSize: '24px' }}>
                              &lt;
                            </button>
                            <h3 style={{ margin: '0', textAlign: 'center', fontSize: '1.25rem', fontWeight: '600' }}>Deuxième vie : {product.prod_name} </h3>
                </div>
                  
                <div className="col-12 text-center"> 
                  <h1 className="text-center mt-3" style={{ fontSize: '1rem', fontWeight: '500' }}>Je donne à un particulier</h1>
                </div> 
                <div className="col-12 card mt-3">
                    <h1 className="text-center mb-3">Geev</h1>
                    <span className="text-left mb-3" style={{
                                    fontSize: '0.875rem',
                                    fontWeight: '400',
                                }} align="justify">En choisissant Geev pour donner votre appareil, vous participez non seulement à la réduction des déchets, mais vous soutenez également l'économie circulaire. Cette plateforme innovante conçue pour faciliter le don d'objets entre particuliers, est pratique, locale, et engagée dans la lutte contre le gaspillage, offrant une solution écologique et économique à ses utilisateurs.</span>
                    <Button 
                            onClick={() => handleClicktoGive(product)}
                        >Je donne avec Geev</Button>
                </div>
            
                <div className="col-12 mt-3 mx-auto"> 
                  <h1 className="text-center mt-3" style={{ fontSize: '1rem', fontWeight: '500',}} alignTe>Je donne à un point de collecte</h1>
                </div>
                <div className="col-12 card mt-3"> 
                    <h1 className="text-center mb-2">Ecosystem</h1>
                    <span className="text-left mb-3" style={{
                                    fontSize: '0.875rem',
                                    fontWeight: '400',
                                }} align="justify">Ecosystem est une organisation dédiée à la promotion du recyclage électronique et au don d'appareils encore utilisables pour minimiser l'impact environnemental. En choisissant de donner votre appareil via Ecosystem, vous facilitez son accès à des entités qui peuvent le réutiliser ou le recycler de manière responsable. Ce geste aide à conserver des ressources naturelles précieuses et à éviter la pollution liée à la production de nouveaux matériaux.</span>
                        <Button 
                            className="mt-2"
                            onClick={() => handleClicktoEcosystem(product)}
                        >Je donne avec Ecosystem</Button>      
                </div>
              </div>
            </div>
          </div>
  );
};

export default Give;