import React, { useState} from 'react';
import { Modal, Button } from 'react-bootstrap';
import ImageUploader from './ImageUploader'; // Assurez-vous que le chemin d'importation est correct
//import CameraCapture from './CameraCapture'; // Assurez-vous que le chemin d'importation est correct


const ImagePopup = ({ src, alt, uploadImageFunction }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);


  
  const stopCamera = () => {
    if (window.cameraStream) {
      window.cameraStream.getTracks().forEach(track => track.stop());
      window.cameraStream = null; // N'oubliez pas de nettoyer la référence
    }
  };


  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    setModalIsOpen(false);
    setIsEditing(false); // Réinitialiser l'état d'édition lors de la fermeture du modal
    stopCamera();
  };
  const toggleEdit = () => setIsEditing(!isEditing);

  return (
    <div>
      <img src={src} alt={alt} onClick={openModal} style={{ cursor: 'pointer', maxWidth: '200px', maxHeight: '200px' }} />
      <Modal show={modalIsOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title className='d-flex mx-auto'>{isEditing ? 'Modifier l\'image' : 'Aperçu de l\'image'}</Modal.Title>
          {!isEditing && (
            <div className='col-4'>
            <Button 
              type="button" 
              onClick={toggleEdit}>
              Modifier
            </Button>
            </div>
          )}
        </Modal.Header>
        <Modal.Body>
          {isEditing ? (
            <ImageUploader uploadImageFunction={uploadImageFunction} />
          ) : (
            <img src={src} alt={alt} style={{ width: '50%' }} />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ImagePopup;
