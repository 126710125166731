import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../css/entretienproduct.css";
import { Button } from "react-bootstrap"; 
import AuthService from "../services/auth.service";
import { useNavigate } from 'react-router-dom';
import SwitchButton from './common/SwitchButton';

import RefproductService from "../services/references.service";

const ProductReplace = () => {
  const [productList, setProductList] = useState([]);

  const navigate = useNavigate();

   const [selectedSwitch, setSelectedSwitch] = useState('Deuxième vie');

   const handleSwitchChange = (newValue) => {
     setSelectedSwitch(newValue);
     console.log('Sélection actuelle:', newValue); // Vous pouvez gérer la sélection comme nécessaire ici
   };


  const handleClicktoReplace = (product) => {
    const replaceproduct={
      "prod_id" : product.id,
      "prod_name" : product.prodName,
      "pict" : product.pict
  }
    // Rediriger vers la page CardDetail et passer l'objet maintenanceCard
    if(selectedSwitch==='Deuxième vie'){
        console.log('allez vers deuxieme vie');
        navigate(`/secondlife/actionselect`, { state: { replaceproduct } });
    }
    if(selectedSwitch==='Rééquipement'){
        console.log('allez vers renew');
        navigate(`/renew/optionselect`, { state: { replaceproduct } });
    }
  };

  
  
  useEffect(() => {
    console.log('Dans inventaire des produits');
    document.body.style.backgroundColor = '#ffffff';

    const user = AuthService.getCurrentUser();
    console.log('User reçue:', user);
    

    console.log('Dans inventaire des produits');
   

    RefproductService.getAllProduct().then((response) => {
      setProductList(response.data);
    })
    .catch((error) => {
      console.error('Une erreur s\'est produite:', error);
    });

    
  }, []); // Le tableau vide en second paramètre signifie que useEffect s'exécutera une fois après le montage initial



  return (
    <div className="product-container justify-content-center"> 
        
         {/* Intégration du SwitchButton */}
         
         <div className="col-12 mx-auto"> 
          <SwitchButton 
            leftLabel="Deuxième vie" 
            rightLabel="Rééquipement"
            onChange={handleSwitchChange} />
            {/* test valeur <p>Option choisie: {selectedSwitch}</p> */}
         </div>



         <div className="col-12 mx-auto"> 
          <h1 className="text-left mt-3 mb-3">Cochez l’équipement auquel vous souhaitez donner une deuxième vie </h1>
          </div>

         <div className="d-flex row"> 
          {productList.map((product) => (
              <div key={product.id} className="col-4 col-md-3 col-lg-3 product mb-4" onClick={() => handleClicktoReplace(product)}>
              <div className="product-link"> 
                <img src={product.pict} alt={product.prodName} className="img-fluid"/>
                <h4 className="product-name ">{product.prodName}</h4>
              </div>
          </div>
          ))}
        </div>
        <div className="col-12 mb-3 mx-auto"> 
                <Button className="mb-3 btn-secondary">Voir plus</Button>
        </div>
        <br/>
        <br/>
        <br/>
      </div>
  );
};

export default ProductReplace;