import React, { useEffect, useState } from "react";
import { IconByType }  from "../common/Icon";
import getLabel, { capitalizeFirstLetter} from "../../services/glossary.service";
import "../../css/smartfix.css";
import SmartfixService from "../../services/smartfix.service";
import QuickChart from 'quickchart-js';


const productCategories = [
  { product_category_id: '1', product_category_FR: 'CLIMATISEUR / DÉSHUMIDIFICATEUR' },
  { product_category_id: '2', product_category_FR: 'BATTERIE / CHARGEUR / ADAPTATEUR' },
  { product_category_id: '3', product_category_FR: 'LUMINAIRES ET GUIRLANDES DÉCO' },
  { product_category_id: '4', product_category_FR: 'ORDINATEUR DE BUREAU' },
  { product_category_id: '5', product_category_FR: 'APPAREIL PHOTO NUMÉRIQUE' },
  { product_category_id: '6', product_category_FR: 'CAMESCOPE' },
  { product_category_id: '7', product_category_FR: 'VENTILATEUR' },
  { product_category_id: '8', product_category_FR: 'ECRAN PLAT' },
  { product_category_id: '9', product_category_FR: 'ÉQUIPEMENT POUR CHEVEUX & BEAUTÉ' },
  { product_category_id: '10', product_category_FR: 'APPAREIL DE DIVERTISSEMENT PORTABLE' },
  { product_category_id: '11', product_category_FR: 'ECOUTEURS' },
  { product_category_id: '12', product_category_FR: 'HI-FI' },
  { product_category_id: '13', product_category_FR: 'COMPOSANTS HI-FI' },
  { product_category_id: '14', product_category_FR: 'BOUILLOIRE' },
  { product_category_id: '15', product_category_FR: 'LAMPE' },
  { product_category_id: '16', product_category_FR: 'ORDINATEUR PORTABLE' },
  { product_category_id: '17', product_category_FR: 'GROS ÉLECTROMÉNAGER' },
  { product_category_id: '18', product_category_FR: 'DIVERS' },
  { product_category_id: '19', product_category_FR: 'TÉLÉPHONE PORTABLE' },
  { product_category_id: '20', product_category_FR: 'INSTRUMENT DE MUSIQUE' },
  { product_category_id: '21', product_category_FR: 'BROYEUSE À PAPIER' },
  { product_category_id: '22', product_category_FR: 'ACCESSOIRE PC' },
  { product_category_id: '23', product_category_FR: 'RADIO PORTABLE' },
  { product_category_id: '24', product_category_FR: 'OUTIL BRICOLAGE' },
  { product_category_id: '25', product_category_FR: 'IMPRIMANTE / SCANNER' },
  { product_category_id: '26', product_category_FR: 'VIDÉO PROJECTEUR' },
  { product_category_id: '27', product_category_FR: 'MACHINE À COUDRE' },
  { product_category_id: '28', product_category_FR: 'PETIT ÉLECTROMÉNAGER DE MAISON' },
  { product_category_id: '29', product_category_FR: 'PETIT ÉLECTROMÉNAGER DE CUISINE' },
  { product_category_id: '30', product_category_FR: 'TABLETTE' },
  { product_category_id: '31', product_category_FR: 'GRILLE PAINS' },
  { product_category_id: '32', product_category_FR: 'JOUET' },
  { product_category_id: '33', product_category_FR: 'ACCESSOIRE TV ET JEUX VIDEOS' },
  { product_category_id: '34', product_category_FR: 'ASPIRATEUR' },
  { product_category_id: '35', product_category_FR: 'MONTRE / RÉVEIL' },
  { product_category_id: '36', product_category_FR: 'MACHINE À CAFÉ' },
  { product_category_id: '37', product_category_FR: 'ROBOT DE CUISINE' },
  { product_category_id: '38', product_category_FR: 'CONSOLE DE JEUX VIDÉO' },
  { product_category_id: '39', product_category_FR: 'SÈCHE CHEVEUX' },
  { product_category_id: '40', product_category_FR: 'FER À REPASSER' },
];


const SmartFix = () => {

  const [response, setResponse] = useState(null);


  const [formState, setFormState] = useState({
    productCategoryId: '',
    brand: '',
    productAge: '',
    problem: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { productCategoryId, brand, productAge, problem } = formState;

    SmartfixService.smartcall(productCategoryId, brand, productAge, problem)
      .then(response => {
        setResponse(response.data);
        console.log('la réponse récupérée:', response.data);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  };

  const getDoughnutChartUrl = (probability) => {
    const chart = new QuickChart();
    const formattedProbability = (probability * 100).toFixed(1);

    chart.setWidth(500);
    chart.setHeight(300);
    chart.setVersion('2');

    chart.setConfig({
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: [parseFloat(formattedProbability), parseFloat((100 - formattedProbability).toFixed(1))],
            backgroundColor: ['green', 'grey'],
            label: 'Dataset 1',
            borderWidth: 10,
          },
        ]
      },
      options: {
        circumference: Math.PI,
        rotation: Math.PI,
        cutoutPercentage: 75,
        layout: {
          padding: 40,
        },
        legend: {
          display: false,
        },
        plugins: {
          doughnutlabel: {
            labels: [
              {
                text: `\n${(probability * 100).toFixed(2)}%`,
                color: '#000',
                font: {
                  size: 40,
                  weight: 'bold',
                },
              },
            ],
          },
        },
      },
    });

    return chart.getUrl();
  };

  return (
  <div>
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="productCategoryId">Catégorie de produit</label>
        <select
          id="productCategoryId"
          name="productCategoryId"
          value={formState.productCategoryId}
          onChange={handleInputChange}
        >
          <option value="">Sélectionnez une catégorie</option>
          {productCategories.map((category) => (
            <option key={category.product_category_id} value={category.product_category_id}>
              {category.product_category_FR}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="brand">Marque</label>
        <input
          type="text"
          id="brand"
          name="brand"
          value={formState.brand}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="productAge">Âge du produit (en années)</label>
        <input
          type="text"
          id="productAge"
          name="productAge"
          value={formState.productAge}
          onChange={handleInputChange}
          pattern="\d*"
        />
      </div>
      <div className="form-group">
        <label htmlFor="problem">Décrire le problème rencontré</label>
        <textarea
          id="problem"
          name="problem"
          value={formState.problem}
          onChange={handleInputChange}
        />
      </div>
      <button type="submit">Rechercher</button>
    </form>
    {response && (
      <div className="mt-4 response">
        <h3>Résultat de l'analyse :</h3>
        <p>Réparable : {response.is_repairable ? 'Oui' : 'Non'}</p>
        <h3>Probabilité de réparation :</h3>
        <img src={getDoughnutChartUrl(response.repair_probability)} alt="Doughnut Chart" />
      </div>
    )}
  </div>
  );
};

export default SmartFix;