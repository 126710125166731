import axios from 'axios';
import authHeader from './auth-header';
// Dans le fichier où vous avez besoin de l'adresse IP
import config from './config';
import UploadService from './upload.service'; // Importez votre fonction getUniver si elle est dans un autre fichier

const API_URL = `${config.serverIP}/${config.apiVersion}`;

class UniverService{

  

  getUniver() {
    // Récupérer l'utilisateur et son ID depuis localStorage
    const user = JSON.parse(localStorage.getItem('user'));
    const userid = user?.id;

    // Vérifier si l'utilisateur est défini avant de continuer
    if (!userid) {
        console.error("User ID not found in localStorage");
        return Promise.reject("User ID not found");
    }

    // Construire l'URL avec l'ID utilisateur
    const url = `${API_URL}/profils/users/${userid}/foyers/univers`;

    // Récupérer les headers d'authentification
    const headers = authHeader();

    // Effectuer la requête GET avec Axios
    return axios.get(url, { headers })
        .then(response => {
            // Gérer la réponse ici si besoin
            
            return response.data;
        })
        .catch(error => {
            // Gérer l'erreur ici
            console.error("Error fetching univers:", error);
            throw error;
        });
  }

  getOldUniver() {
    // Récupérer l'utilisateur et son ID depuis localStorage
    const user = JSON.parse(localStorage.getItem('user'));
    const userid = user?.id;

    // Vérifier si l'utilisateur est défini avant de continuer
    if (!userid) {
        console.error("User ID not found in localStorage");
        return Promise.reject("User ID not found");
    }

    // Construire l'URL avec l'ID utilisateur
    const url = `${API_URL}/profils/users/${userid}/foyers/customunivers`;

    // Récupérer les headers d'authentification
    const headers = authHeader();

    // Effectuer la requête GET avec Axios
    return axios.get(url, { headers })
        .then(response => {
            // Gérer la réponse ici si besoin
            
            return response.data;
        })
        .catch(error => {
            // Gérer l'erreur ici
            console.error("Error fetching univers:", error);
            throw error;
        });
  }

  creatUniver = async (refUniverId, file, name,description) => {

    const MAX_SIZE = 200 * 1024; // Limite de 200Ko

    // Si la taille du fichier dépasse la limite, redimensionnez-le
    let fileToUpload = file;
    console.log("filetoupload in service ", file);
    console.log("filetoupload size ", file.size);
    if (file.size > MAX_SIZE) {
      console.log("Redimensionnement de l'image car elle dépasse 200Ko...");
      fileToUpload = await UploadService.resizeImage(file);
    }

    console.log("filetoupload after resize ", fileToUpload);
    
    // Récupérer l'utilisateur et son ID depuis localStorage
    const user = JSON.parse(localStorage.getItem('user'));
    const userId = user?.id;

    // Vérifier si l'utilisateur est défini avant de continuer
    if (!userId) {
        console.error("User ID not found in localStorage");
        return Promise.reject("User ID not found");
    }

    // Construire l'URL avec l'ID utilisateur
    const url = `${API_URL}/profils/users/${userId}/foyers/univers`;


    const formData = new FormData();
    formData.append('file', fileToUpload);
    formData.append('name', name); // Ajoutez le champ 'name'
    formData.append('refUniverId', refUniverId); // Ajoutez le champ 'name'
    formData.append('description', description); // Ajoutez le champ 'name'
  
    // Récupérer les headers d'authentification
    const headers = authHeader();


    try {
      const response = await axios.post(url, formData, { headers });
      console.log('Univer créé avec succès:', response.data);
      return response.data;
    } catch (error) {
      console.error('Erreur lors de la création de l\'univer:', error);
    }
  };

   
  updateUniver = async (univerId, file, name, description) => {

    const MAX_SIZE = 200 * 1024; // Limite de 200Ko
  
    // Si un fichier a été modifié, vérifier sa taille et le redimensionner si nécessaire
    let fileToUpload = file;
    if (file && file.size > MAX_SIZE) {
      console.log("Redimensionnement de l'image car elle dépasse 200Ko...");
      fileToUpload = await UploadService.resizeImage(file);
    }
  
    // Récupérer l'utilisateur et son ID depuis localStorage
    const user = JSON.parse(localStorage.getItem('user'));
    const userId = user?.id;
  
    // Vérifier si l'utilisateur est défini avant de continuer
    if (!userId) {
      console.error("User ID not found in localStorage");
      return Promise.reject("User ID not found");
    }
  
    // Construire l'URL avec l'ID utilisateur
    const url = `${API_URL}/profils/users/${userId}/foyers/univers/${univerId}`;
  
    const formData = new FormData();
  
    // Ajouter uniquement les champs modifiés
    if (fileToUpload) {
      formData.append('file', fileToUpload);
    }
  
    if (name) {
      formData.append('name', name);
    }
  
    if (description) {
      formData.append('description', description);
    }
  
    // Récupérer les headers d'authentification
    const headers = authHeader();
  
    try {
      const response = await axios.put(url, formData, { headers });
      console.log('Univer mis à jour avec succès:', response.data);
      return response.data;
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'univer:', error);
    }
  };
  


    
}


// Créez une instance de NotifService
const UniverServiceInstance = new UniverService();

// Exportez l'instance en tant qu'export par défaut
export default UniverServiceInstance;


//export default new EventService();