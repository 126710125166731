
import ImageUploader  from "../common/ImageUploader";
import { Button, Modal,Spinner } from "react-bootstrap"; 
import React, { useState,  useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/entretienproduct.css";
import { useLocation } from 'react-router-dom';

import SearchService from "../../services/search.service";

import UploadService from "../../services/upload.service";

const AppSearch = () => {
    const location = useLocation();
    const { state } = location|| {};
    const { appliance } = state|| {};
    console.log("appareil reçu ",appliance);
  
    const [searchText, setSearchText] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    const [toSearch, setToSearch] = useState("true");
  

    const [resultsearch, setResultSearch] = useState([]);

    const handleClicktoSearch = async (appliance) => {
        setIsLoading(true); // Active le popup de chargement
        try {
            const url = await UploadService.uploadFile(appliance);
            const resultat = await SearchService.searimage(url, searchText);
            setResultSearch(resultat);
            setToSearch(false);
        } catch (error) {
            console.error('Erreur lors de la recherche :', error);
            // Gérez l'erreur si nécessaire
        }
        setIsLoading(false); // Désactive le popup de chargement
    };

    // Fonction pour gérer les changements de texte dans l'input
    const handleSearchInputChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleClicktoNewRecherche = (value) => {
        setToSearch(value);
    };
    
    
    useEffect(() => {
      
      console.log('Dans détail des produits');
      document.body.style.backgroundColor = '#ffffff';
  
      console.log('Dans inventaire des produits');
    
    }, []); // Le tableau vide en second paramètre signifie que useEffect s'exécutera une fois après le montage initial
 
    return (
    <div className="product-container container-fluid justify-content-center"> 
        
            <h1 className="mb-3 mt-3">Fiche Recherche Produit</h1>
            
            <Modal
                show={isLoading}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Body>
                    <div className="text-center">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Chargement...</span>
                    </Spinner>
                    <p>Chargement...</p>
                    </div>
                </Modal.Body>
            </Modal>

            {(toSearch) && ( 
            <div className="row">
                <div className="col-12">
                    <h1 className="mb-2 mt-2">Type du produit</h1>
                    <input
                        type="text"
                        value={searchText}
                        onChange={handleSearchInputChange}
                        className="form-control"
                        placeholder=""
                    />
                </div>
                <div className="col-12 info-appliance">
                    <h1 className="mb-2 mt-2">Ajoute l'image du produit</h1>
                    <ImageUploader uploadImageFunction={handleClicktoSearch} />
                </div>
            </div>
            )}

            {(!toSearch) && (
            <div className="row"> 
                <div>
                    <h1 className="mb-2 mt-2">Résultat to Search</h1>
                    <h5 className="mb-2 mt-2">{resultsearch.marque}</h5>
                    <h5 className="mb-2 mt-2">{resultsearch.ref}</h5>
                </div>
                <div className="col-12 col-md-6 col-lg-6 mb-4" onClick={() => handleClicktoNewRecherche(true)}> 
                    <Button>Relancer une autre recherche</Button>
                </div>
            </div>
            )}
          <br/>
          <br/>
          <br/>
      </div>
    );
  };
  
  export default AppSearch;