import React from 'react';
import { Link } from 'react-router-dom';

const Banner = () => {
    console.log("Show banner");


/*highlight
<span className="highlight-container-red">
                    <span className="highlight" style={{ fontWeight:"700", color: "var(--color-default)" }}>  entretiens </span> 
                    </span><span> 

                     <div
                
    style={{ 
        margin: "0px",
        padding: "0px",
        height:"140px",
        borderRadius: "0px", 
        position: "relative",
        overflow: "hidden",
        boxSizing: "border-box"
    }}>
    <img src={process.env.PUBLIC_URL + '/icons/Banner.svg'} 
    alt=""
    style={{ 
    position: "absolute",
    top: "-20px",
    right: "-30px",
    width: '250px', 
    height: 'auto',
    opacity: 0.9, // Optionnel : ajuster l'opacité pour rendre le texte plus lisible
    transform: 'rotate(0deg)'
    }}  />
*/

  return (
    <div
                
    style={{ 
        margin: "0px",
        padding: "0px",
        height:"163px",
        width: "100%",
        borderRadius: "0px", 
        position: "relative",
        overflow: "hidden",
        boxSizing: "border-box",
        backgroundColor: "#F7F8FC",
       
    }}>
    <img src={process.env.PUBLIC_URL + '/icons/Banner.svg'} 
    alt=""
    style={{ 
    position: "absolute",
    right: "0px",
    width: 'auto', 
    height: '163px'
    }}  />
    
 <div style={{ 
            position: "relative",
            zIndex: 2, // Placer ce div au-dessus de l'image
            height:"100%",
            width:"100%",
            borderRadius: "0px",
            boxSizing: "border-box",
            borderTop: "1px solid lightgray",   // Add top border
            borderBottom: "1px solid lightgray", // Add bottom border
            alignContent: "center"
            }}>
            <h1 className="text-banner">
                    <span>Un jardin bien entretenu donne de belles fleurs. </span>
                    <span>Planifier les prochains entretien de vos appareils </span> 
            </h1>
            <div 
                className="col-4 col-md-2 col-lg-2  mt-2 text-center"
                style={{
                    background:"var(--color-green)",
                    borderRadius:"0.25rem",
                    color: "white",  
                    textDecoration: "none",
                    height: "25px",
                    marginLeft: "20px"
                    }}>
            <a 
                        style={{
                           alignContent:"middle",
                            color: "white",              
                            fontSize:"12px",
                            fontWeight:"500",
                            textDecoration: "none",
                            }} href="/#/calendar">EN SAVOIR PLUS &rarr; {/* Flèche droite simple */}</a>
    </div>      
          
    </div>
    </div >
    );
};

export default Banner;