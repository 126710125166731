
import React, { useState,useEffect} from "react";
import ImageUploader  from "../common/ImageUploader";
import { Button, Modal,Form  } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/entretienproduct.css";
import { useLocation } from 'react-router-dom';

import AuthService from "../../services/auth.service";
import { useNavigate } from 'react-router-dom';

import WaitingModal from "../common/WaitingModal"; 
import SearchService from "../../services/search.service";

import UploadService from "../../services/upload.service";


const ApplianceAdd = () => {
  
  const [searchText, setSearchText] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const navigate = useNavigate();
  
  const [brand, setBrand] = useState('');
  const [model, setModel] = useState('');


  const location = useLocation();
  const { state } = location|| {};
  const { product } = state|| {};
  console.log("produit reçu ",product);

  const handleFormAppSave = (appliance) => {
    // Vérifiez si les champs obligatoires sont remplis avant d'enregistrer les données
    if (brand && model) {
      console.log("Formulaire enregistré !");
      setShowModal1(false);
    } else {
      alert("Veuillez remplir les champs obligatoires !");
    }
  };

  const handleClicktoAddByForm = (appliance) => {
    if(searchText){
      // Ouvrir la modal 1
      setShowModal1(true);
    } else {
      alert("Veuillez renseigner le type du produit !");
    }
  };

  const handleClicktoAddByImage = () => {
    if(searchText){
      // Ouvrir la modal 1
      setShowModal2(true);
    } else {
      alert("Veuillez renseigner le type du produit !");
    }
  };

  const handleCloseModal1 = () => {
    setShowModal1(false);
  };

  const handleCloseModal2 = () => {
    setShowModal2(false);
  };

 // Fonction pour gérer les changements de texte dans l'input
  const handleSearchInputChange = (event) => {
      setSearchText(event.target.value);
  };

  const handleClicktoSearch = async (image) => {
    setIsLoading(true); // Active le popup de chargement
    //let isFound=false;
    //var appliance;
    try {
        const user = AuthService.getCurrentUser();
        const url = await UploadService.uploadFile(image);
        setIsLoading(true);
        setShowModal2(false);
        await SearchService.searchAndSaveByImage(url, searchText,user.id);
        //isFound=true;
    } catch (error) {
        console.error('Erreur lors de la recherche :', error);
        // Gérez l'erreur si nécessaire
    }finally {
      setIsLoading(false); // Désactive le popup de chargement
      navigate(`/inventaire`);
      /*
      if (isFound) {
        //navigate(`/inventaire/fichedetail`, { state: { appliance } });

      }*/
    }
  };

  useEffect(() => {
    // Met à jour searchText avec la valeur de product.prodName si product.prodName n'est pas "Autres"
    if (product.prodName !== "Autres") {
      setSearchText(product.prodName);
    }
  }, [product.prodName]); // Déclenche l'effet à chaque changement de product.prodName


  return (
    <div className="product-container container-fluid justify-content-center">
      {/* Affichez le modal de chargement si isLoading est true */}
      {isLoading && <WaitingModal />}
      <div className="row">
        <h1 className="mb-3 mt-3">Fiche Produit</h1>
        {product.prodName !== "Autres" ? (
        <div className="col-12 col-md-6 col-lg-6 mb-3">
          <p>Type de produit : {product.prodName}</p>
          <img src={product.pict} alt="Product" className="img-fluid"/>
      </div>
      ):(
        <div className="col-12 col-md-6 col-lg-6  mb-3">
            <p className="mb-1 mt-2">Type du produit <span className="text-danger">*</span></p>
            <input
                type="text"
                value={searchText}
                onChange={handleSearchInputChange}
                className="form-control"
                placeholder=""
            />
        </div>
      )}
      </div>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-6 mb-4" onClick={() => handleClicktoAddByImage()}> 
              <Button variant="secondary">Rechercher par image</Button>
          </div>

          <div className="col-12 col-md-6 col-lg-6 mb-4" onClick={() => handleClicktoAddByForm()}> 
              <Button variant="secondary">Ajouter manuel</Button>
          </div>
      </div>

      {/* Modal 1 */}
      <Modal show={showModal1} onHide={handleCloseModal1}>
        <Modal.Header closeButton>
          <Modal.Title><h1 className="mb-2 mt-2">Renseigner le produit</h1></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><strong>Type de produit</strong> : {product.prodName}</p>
          <Form>
              <Form.Group controlId="formBrand">
                <Form.Label><strong>Marque</strong> <span className="text-danger">*</span></Form.Label>
                <Form.Control 
                  type="text" 
                  placeholder="Entrez la marque" 
                  value={brand} 
                  onChange={(e) => setBrand(e.target.value)} 
                  required // Champ obligatoire
                />
              </Form.Group>

              <Form.Group controlId="formModel">
                <Form.Label><strong>Modèle</strong> <span className="text-danger">*</span></Form.Label>
                <Form.Control 
                  type="text" 
                  placeholder="Entrez le modèle" 
                  value={model} 
                  onChange={(e) => setModel(e.target.value)} 
                  required // Champ obligatoire
                />
              </Form.Group>

              <Form.Group controlId="formPurchaseDate" className="mt-2">
                <Form.Label><strong>Date d'achat</strong></Form.Label>
                <Form.Control type="date" placeholder="Entrez la date d'achat" />
              </Form.Group>

              <Form.Group controlId="formNote" className="mt-2">
                <Form.Label><strong>Note</strong></Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Entrez la note" />
            </Form.Group>

            {/* Composant ImageUploader dans le formulaire */}
            <Form.Group controlId="formImageUploader" className="mt-2">
              <Form.Label><strong>Télécharger la facture</strong></Form.Label>
              <ImageUploader uploadImageFunction={handleClicktoSearch} />
            </Form.Group>
          </Form>
           
        </Modal.Body>
        <Modal.Footer>
           {/* Bouton "Enregistrer" */}
           <Button variant="secondary" onClick={handleFormAppSave}>
              Enregistrer
            </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal 2 */}
      <Modal show={showModal2} onHide={handleCloseModal2}>
        <Modal.Header closeButton>
          <Modal.Title><h1 className="mb-2 mt-2">Ajouter l'image du produit</h1></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 info-appliance">
              <ImageUploader uploadImageFunction={handleClicktoSearch} />
          </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>



    </div>
  );
  
};

export default ApplianceAdd;