import axios from "axios";

import authHeader from './auth-header';
// Dans le fichier où vous avez besoin de l'adresse IP
import config from './config';

const API_URL = `http://0.0.0.0:10040/predict`;

class SmartfixService{

    smartcall(product_category_id,brand,product_age,problem) {
        const req = {
          "product_category_id": product_category_id,
          "brand": ''+brand,
          "product_age": product_age,
          "problem": ''+problem
        }

        console.log('smarfix sent ',req);
        let url=API_URL;
        return axios.post(url,req,{ headers: authHeader() });
    }

    
}
// Créez une instance de NotifService
const smartfixServiceInstance = new SmartfixService();

// Exportez l'instance en tant qu'export par défaut
export default smartfixServiceInstance;