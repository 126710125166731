import React,{ useState, useEffect}from 'react';
import UniverCRUD from '../common/UniverCRUDForm';
import EventBus from "../../common/EventBus";
import ReferencesServiceInstance from '../../services/references.service';
import UniverServiceInstance from '../../services/univer.service';

import RadioButtonChoice from '../common/RadioButtonChoice';

import DropDownAndSearch from '../common/DropDownAndSearch';

const UniverSelect = ({univer,nextstep, setUniverChoice}) => {

  const [refUnivers, setRefUnivers] = useState(null);
  const [oldUnivers, setOldUnivers] = useState(null);
  const [listUniversShow, setlistUniversShow] = useState(null);
  

  const [selectedUniver, setSelectedUniver] = useState(null);
  const [showCustomUniver, setShowCustomUniver] = useState(false);
  const [addMethod, setAddMethod] = useState("");
  
  const addMethods = [
    { id: 'old', value: 'old', label: 'Parmi les existant' },
    { id: 'new', value: 'new', label: 'Nouvel univer' },
  ];
  
  const handleClose = (workedUniver) => {
    if(workedUniver) {
    console.log("Univers retravaillé ", workedUniver);
    setShowCustomUniver(false);
    console.log("Univers OK1");
    setUniverChoice(workedUniver);
    console.log("Univers OK2");
    nextstep();
    console.log("Univers OK3");
    }else {
      //le choix de l'univer est annulé
      setSelectedUniver(null);
      setShowCustomUniver(false);
      setUniverChoice(null);
    }
    // Rediriger avec l'objet univer mis à jour dans l'état
    //navigate("/inventaire/univers");
  };

  const handleSelectChange = (univer) => {
    //ajout univer_id et refuniver_id pour univerCRUD
    const updatedUniver = { ...univer, univer_id: -1, refuniver_id: univer.id };
    setSelectedUniver(updatedUniver || null);
    setShowCustomUniver(false);
  };

  const gotoCustomUniver = () => {
    if(addMethod==='new') setShowCustomUniver(true);
    //sinon utilise directement avec univer old
    else {
      console.log("Univers retravaillé ", selectedUniver);
      setUniverChoice(selectedUniver);
      nextstep();
    }
  };

  const listUniversChoice = (method) => {
    if (method==='old') setlistUniversShow(oldUnivers);
    else setlistUniversShow(refUnivers);
    setAddMethod(method);
    
 };

  const fetchRefUniversData = async () => {
    try {

      // Appel de l'API avec async/await
      const response = await ReferencesServiceInstance.getRefUniver();
      
      // Vérifie si la réponse est bien un tableau, sinon assigne un tableau vide
      const refuniversData = Array.isArray(response) ? response: [];
      setRefUnivers(refuniversData);

      // Appel de l'API avec async/await
      const response2 = await UniverServiceInstance.getOldUniver();
      
      // Vérifie si la réponse est bien un tableau, sinon assigne un tableau vide
      const oldUniversData = Array.isArray(response2) ? response2: [];
      setOldUnivers(oldUniversData);

  
      console.log('Dans inventaire des refunivers', response);

      console.log('Dans inventaire des customunivers', response2);
    } catch (error) {
      console.error('Une erreur s\'est produite:', error);
  
      if (error.response && error.response.status === 401) {
        EventBus.dispatch("logout");
      }
    }
  };

  useEffect(() => {
    if (!refUnivers && !oldUnivers) fetchRefUniversData();
    if (addMethod==='old') setlistUniversShow(oldUnivers);
    else setlistUniversShow(refUnivers);
  }, []);
console.log('liste to be showed',listUniversShow)
  return (
    <div>
      {!showCustomUniver && (
        <div>
          <div className='mb-2 text-medium'>Choix de l'univer</div>
          
          <RadioButtonChoice
            legend={``}
            options={addMethods}
            selectedValue={addMethod}
            onChange={listUniversChoice}
          />
          
          {listUniversShow && (
          <div>
              <div className="mt-3">
                  {/* Liste déroulante */}
                  <DropDownAndSearch 
                    productList={listUniversShow} 
                    onSelect={handleSelectChange} 
                    displayField="name" 
                    placeholder="Sélectionnez l'univer" 
                  />

                  {/* Carré affichant les détails de l'univers sélectionné */}
                  {selectedUniver && (
                    <div className="mt-3 green-border" style={{ padding: '0.5rem', display: 'flex', alignItems: 'center' }}>
                      <div>
                        <img src={selectedUniver.image} alt={selectedUniver.name} style={{ width: '100px', height: '100px' }} />
                      </div>
                      <div className="text-small" style={{ marginLeft: '1rem' }}>{selectedUniver.text}</div>
                    </div>
                  )}
              </div>
              <button className="mt-3 mb-3 btn btn-primary btn-block"
                    onClick={gotoCustomUniver}>
                      <span>CONFIRMER</span>
              </button>
          </div>)}
        </div>
      )}

      {selectedUniver &&  showCustomUniver && (
          <div className="mb-3">
            <UniverCRUD show={showCustomUniver} onClose={handleClose} univer={selectedUniver} action='CREATE' isModal={false}/>
        </div>)}
      
    </div>
  );
};

export default UniverSelect;
