import axios from 'axios';
import authHeader from './auth-header';
// Dans le fichier où vous avez besoin de l'adresse IP
import config from './config';

const API_URL = `${config.serverIP}/api/maintenancecard`;

//localhost:8090/api/maintenancecard?productid=1

class RefMaintenanceCardService {
  getMaintenanceCardByProduct(productid) {
    console.log('ID du produit à récupérer :', productid);
    let url=API_URL+'?productid='+productid;
    console.log('url  :', url);
    return axios.get(url,{ headers: authHeader() });
  }
}

// Créez une instance 
const RefMaintenanceCardServiceInstance = new RefMaintenanceCardService();

// Exportez l'instance en tant qu'export par défaut
export default RefMaintenanceCardServiceInstance;


//export default new RefMaintenanceCardService();