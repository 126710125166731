import { Navigate} from "react-router-dom";
import AuthService from "../services/auth.service";
import React, { Component } from "react";

export default class Welcome extends Component {


    constructor(props) {
        super(props);
    
        this.state = {
          redirect: null,
          userReady: false,
          currentUser: undefined,//{ username: "" }
        };
      }
    
      componentDidMount() {
        const currentUser = AuthService.getCurrentUser();
        console.log('Hello! in profile ');
    
        if (!currentUser) {
            console.log('no user! redirect to login page ');
            this.setState({ redirect: "/login" });
        }
        else  { 
            console.log('currentUser! '+currentUser);
            this.setState({ redirect: "/home" });
        }
      }


  render(){

        return <Navigate to={this.state.redirect} />
    }
}