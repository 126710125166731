import React, { useState } from 'react';

import "../../css/entretienproduct.css";

const SwitchButton = ({ leftLabel, rightLabel, onChange }) => {
  const [leftChecked, setLeftChecked] = useState(true);

  const handleSwitch = (selectedSwitch) => {
    const isLeft = selectedSwitch === 'left';
    if (selectedSwitch === 'left') {
      setLeftChecked(true);
    } else if (selectedSwitch === 'right') {
      setLeftChecked(false);
    }
    onChange?.(isLeft ? leftLabel : rightLabel);
  };

  return (
    <div className="mt-4 mb-4 d-flex justify-content-between align-items-center text-center custom-switch-container">
      <div
        className={`custom-switch text-center ${leftChecked ? 'checked' : ''}`}
        onClick={() => handleSwitch('left')}
      >
        <input
          type="checkbox"
          className="custom-control-input"
          id="left-switch"
          checked={leftChecked}
          readOnly
        />
        <span className="custom-control-indicator"></span>
        <span className="custom-control-description">{leftLabel}</span>
      </div>

      <div
        className={`custom-switch ${!leftChecked ? 'checked' : ''}`}
        onClick={() => handleSwitch('right')}
      >
        <input
          type="checkbox"
          className="custom-control-input"
          id="right-switch"
          checked={!leftChecked}
          readOnly
        />
        <span className="custom-control-indicator"></span>
        <span className="custom-control-description">{rightLabel}</span>
      </div>
    </div>
  );
};


export default SwitchButton;
