import axios from 'axios';
import authHeader from './auth-header';

import config from './config';

const API_URL = `${config.serverIP}/${config.apiVersion}/references`;


class ReferencesService {

  getAllProduct() {

    // Construire l'URL
    const url = `${API_URL}/refproducts`;
    // Récupérer les headers d'authentification
    const headers = authHeader();
    
    // Effectuer la requête GET avec Axios
    return axios.get(url, { headers })
        .then(response => {
            // Gérer la réponse ici si besoin
            return response.data;
        })
        .catch(error => {
            // Gérer l'erreur ici
            console.error("Error fetching refproducts:", error);
            throw error;
        });
  }

  getRefUniver(){
    // Construire l'URL avec l'ID utilisateur
    const url = `${API_URL}/refunivers`;

    // Récupérer les headers d'authentification
    const headers = authHeader();

    // Effectuer la requête GET avec Axios
    return axios.get(url, { headers })
        .then(response => {
            // Gérer la réponse ici si besoin
            return response.data;
        })
        .catch(error => {
            // Gérer l'erreur ici
            console.error("Error fetching refunivers:", error);
            throw error;
        });
  }
}


// Créez une instance 
const ReferencesServiceInstance = new ReferencesService();

// Exportez l'instance en tant qu'export par défaut
export default ReferencesServiceInstance;


//export default new RefproductService();