export default function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));
    
       
    if (user && user.accessToken) {
      //console.log('Prepare header to call api '+user.username);
      //console.log('Bearer '+user.accessToken );
     
      return { Authorization: 'Bearer ' + user.accessToken }; // for Spring Boot back-end
      //return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
    } else {
      return {};
    }
  }