import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/App.css";

import AuthService from "./services/auth.service";
import EventBus from "./common/EventBus";


import Navigation from "./components/navigation";

import Header from "./components/common/Header";
import Banner from "./components/common/Banner";

import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home.component";
import Welcome from "./components/welcome";
import Profile from "./components/profile.component";
import BoardUser from "./components/board-user.component";
import BoardFreemium from "./components/board-freemium.component";
import BoardAssure from "./components/board-assure.component";
import ProductList from "./components/maintenance/productlist";
import MaintenanceCard from "./components/maintenance/maintenancecard";
import MaintenanceCardDetail from "./components/maintenance/maintenancecarddetail";
import ViewCalendar from "./components/calendar/viewcalendar";
import SmartFix from "./components/smartfix/smartform";
import ViewNotif from "./components/notification/viewnotif";
import ApplianceList from "./components/portfolio/appliancelist";
import Univers from "./components/portfolio/univers";
import UniverDetail from "./components/portfolio/univerdetail";

import ApplianceDetail from "./components/portfolio/appliancedetail";
import ApplianceUpdateForm from "./components/portfolio/ApplianceUpdateForm";
import ApplianceAdd from "./components/portfolio/applianceadd";


import AddDevicePage from "./components/newdevice/DeviceAddPage";

import AppSearch from "./components/portfolio/appsearch";
import ApplianceReplace from "./components/replaceappliance";
import ProductReplace from "./components/replaceproduct";
import ActionSelect from "./components/secondlife/actionselect";
import Give from "./components/secondlife/give";
import Sell from "./components/secondlife/sell";
import Recycle from "./components/secondlife/recycle";
import OptionSelect from "./components/renew/optionselect";
import CircularTip from "./components/renew/circulartip";
import FundSearch from "./components/renew/fundsearch";
import RenewOffer from "./components/renew/renewoffer";
import AuthVerify from "./common/auth-verify";

function App() {
  const [showFreemiumBoard, setShowFreemiumBoard] = useState(false);
  const [showAssureBoard, setShowAssureBoard] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);
  const navigate = useNavigate();

  // Définir l'état pour contrôler l'affichage du Header et du Banner
  const [showBanner, setShowBanner] = useState(false);//par défault on affiche pas banner
  const [showHeader, setshowHeader] = useState(true);//par défaut on affiche le header

  const location = useLocation();
  /*
    // Définir les routes où le Banner ne doit pas être affiché
    const showBannerRoutes = [
      '/home'
    ];

    // Définir les routes où le Banner ne doit pas être affiché
    const hideHeaderRoutes = [
      '/home'
    ];

    // Vérifier si la route actuelle correspond à l'une de ces routes
    const showBanner = showBannerRoutes.includes(location.pathname);
    // Vérifier si la route actuelle correspond à l'une de ces routes
    const hideHeader = hideHeaderRoutes.includes(location.pathname);
  */
  useEffect(() => {

    // Modifier l'affichage du Header et du Banner en fonction de la route active
    switch (location.pathname) {
      case '/login':
      case '/register':
      case '/profile':
      case '/calendar':
      case '/notification':
      case '/entretien/fiche':
      case '/entretien/fichedetail':
        setshowHeader(false);
        setShowBanner(false);
        break;
      case '/home':
        setshowHeader(true);
        setShowBanner(true);
        break;
      default:
        setshowHeader(true);
        setShowBanner(false);
        break;
    }



    const user = AuthService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
      setShowFreemiumBoard(user.roles.includes("ROLE_FREEMIUM"));
      setShowAssureBoard(user.roles.includes("ROLE_ASSURE"));
    } else {
      logOut();
      navigate("/login");
    }
    
    // Capture global errors
    window.onerror = (message, source, lineno, colno, error) => {
      console.log("Global error captured:", message);
      // Affiche un message d'erreur à l'utilisateur
     // alert("Une erreur s'est produite, vous allez être redirigé vers la page d'accueil.");

      // Vérifiez si l'utilisateur est connecté
      if (user) {
       // navigate("/home");  // Redirige vers la page d'accueil
      } else {
        navigate("/login");  // Redirige vers la page de connexion
      }
      //window.location.reload();  // Rafraîchit la page après navigation
    };


    EventBus.on("logout", logOut);

    // Cleanup event listener on unmount
    return () => {
      EventBus.remove("logout");
    };
  }, [navigate]);

  function logOut() {
    AuthService.logout();
    setShowFreemiumBoard(false);
    setShowAssureBoard(false);
    setCurrentUser(undefined);
    navigate("/login");
  }

  return (
  
    <div className=" mb-4">  
      {showHeader && <Header />}
      {showBanner && <Banner />} 
      {currentUser ? <Navigation/> : <div />}
      <div className="container app-container container-fluid ">
      
        <Routes>
          <Route path="" element={<Welcome />} />
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home /> }/>

          <Route path="/entretien/fiche" element={<MaintenanceCard />} />
          <Route path="/entretien/fichedetail" element={<MaintenanceCardDetail />} />
          
          <Route path="/productlist" element={<ProductList />} />
          
          <Route path="/calendar" element={<ViewCalendar />} />
          
          <Route path="/notification" element={<ViewNotif />}/>
          
          <Route path="/inventaire/univers" element={<Univers />} />
          <Route path="/inventaire/univers/:id/detail" element={<UniverDetail />} />
          <Route path="/inventaire/univers/appliance/fichedetail" element={<ApplianceDetail />} />
          <Route path="/inventaire/univers/appliance/update" element={<ApplianceUpdateForm />} />

          <Route path="/inventaire" element={<ApplianceList />} />
          <Route path="/inventaire/nouveau_2" element={<ApplianceAdd />} />
          <Route path="/inventaire/search" element={<AppSearch />} />

          <Route path="/new-device" element={<AddDevicePage />} />


          
          <Route path="/replace/appliance" element={<ApplianceReplace />} />
          <Route path="/replace/product" element={<ProductReplace />} />
          
          <Route path="/secondlife/actionselect" element={<ActionSelect />} />
          <Route path="/secondlife/give" element={<Give />} />
          <Route path="/secondlife/sell" element={<Sell />} />
          <Route path="/secondlife/recycle" element={<Recycle />} />
          
          <Route path="/renew/optionselect" element={<OptionSelect />} />
          <Route path="/renew/fundsearch" element={<FundSearch />} />
          <Route path="/renew/tip" element={<CircularTip />} />
          <Route path="/renew/offer" element={<RenewOffer />} />
          
          <Route path="/smarfix" element={<SmartFix />} />
          
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />}/>
          <Route path="/profile" element={<Profile />}/>
          <Route path="/user" element={<BoardUser />} />
          
          <Route path="/freemium" element={<BoardFreemium />} />
          <Route path="/assure" element={<BoardAssure />} />
        </Routes>
      </div>
      <AuthVerify logOut={logOut} />
      <br/><br/><br/>
      
    </div>
  );
}

export default App;
