// RadioButtonChoice.js
import React from 'react';

const RadioButtonChoice = ({ legend, options, selectedValue, onChange }) => {
  return (
    <fieldset>
      <legend className="text-medium">{legend}</legend>
      <div className="d-flex mt-2 text-center">
        {options.map(({ id, value, label }) => (
          <div key={id} className="form-check form-check-inline custom-radio text-small d-flex align-items-center">
            <input
              className="form-check-input"
              type="radio"
              name="radioOption"
              id={id}
              value={value}
              checked={selectedValue === value}
              onChange={() => onChange(value)}
              aria-label={label}
            />
            <label className="text-center form-check-label ms-2 mb-0" htmlFor={id}>{label}</label>
          </div>
        ))}
      </div>
    </fieldset>
  );
};

export default RadioButtonChoice;
