import { useState } from 'react';

const DropDownAndSearch = ({ productList, onSelect, displayField, placeholder }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

 if (!productList) return <div></div>;
 
   const filteredProducts = searchTerm
    ? productList.filter(product =>
        product[displayField].toLowerCase().includes(searchTerm.toLowerCase())
      )
    : productList; // Affiche tous les produits si searchTerm est vide

  const handleSelect = (product) => {
   setSearchTerm(product[displayField]);
    setIsDropdownOpen(false);
    onSelect(product);
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setIsDropdownOpen(true);
  };

  return (
    <div className="mb-3 text-small" style={{ position: 'relative', width: '100%' }}>
      <input
        type="text"
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleInputChange}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        style={{
          width: '100%',
          padding: '8px',
          boxSizing: 'border-box',
          cursor: 'pointer',
        }}
      />
      {isDropdownOpen && (
        <ul
          style={{
            position: 'absolute',
            width: '100%',
            maxHeight: '150px',
            overflowY: 'auto',
            margin: 0,
            padding: 0,
            listStyle: 'none',
            backgroundColor: 'white',
            border: '1px solid #ccc',
            boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
            zIndex: 1000,
          }}
        >
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product) => (
              <li
                key={product.id}
                onClick={() => handleSelect(product)}
                style={{
                  padding: '8px',
                  cursor: 'pointer',
                  backgroundColor: '#fff',
                  borderBottom: '1px solid #eee',
                }}
                onMouseEnter={(e) => (e.target.style.backgroundColor = '#f0f0f0')}
                onMouseLeave={(e) => (e.target.style.backgroundColor = '#fff')}
              >
                {product[displayField]}
              </li>
            ))
          ) : (
            <li style={{ padding: '8px', color: '#888' }}>Aucun résultat</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default DropDownAndSearch;
