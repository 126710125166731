import axios from 'axios';
import authHeader from './auth-header';
// Dans le fichier où vous avez besoin de l'adresse IP
import config from './config';

const API_URL = `${config.serverIP}/${config.apiVersion}/profils/users`;

class ApplianceService {

  getApplianceByUser(userId) {
    console.log('ID du user à récupérer :', userId);
     // Construire l'URL avec l'ID utilisateur et univerid
    const url = `${API_URL}/${userId}/appliances`;
    console.log('url  :', url);
    return axios.get(url,{ headers: authHeader() });
  }

  getApplianceByUserAndUniver(userId, univerId) {
    console.log('ID du user à récupérer :', userId);
     // Construire l'URL avec l'ID utilisateur et univerid
    const url = `${API_URL}/${userId}/foyers/univers/${univerId}/appliances`;
    //const url=API_URL+'/list?userid='+userid+'&univerid='+univerid;
    console.log('url  :', url);
    return axios.get(url,{ headers: authHeader() });
  }

  getApplianceDetail(applianceId) {
    console.log('ID appareil à récupérer :',applianceId);
     // Construire l'URL avec l'ID utilisateur et univerid
    const url = `${API_URL}/foyers/univers/appliances/${applianceId}`;
    console.log('url  :', url);
    return axios.get(url,{ headers: authHeader() });
  }

  updateApplianceText = async (userId, appliance) => {
    try {
      const url = `${API_URL}/${userId}/foyers/univers/appliances/${appliance.id}/text`;
      const response = await axios.put(url, appliance);
      return response.data;
    } catch (error) {
      console.error('Erreur lors de la mise à jour :', error);
      throw error; // relance l'erreur pour la gestion dans le composant si nécessaire
    }
  };

  updateApplianceFacture = async (userId, applianceId, facture) => {
    try {
      const url = `${API_URL}/${userId}/foyers/univers/appliances/${applianceId}/facture`;
      const formData = new FormData();
      formData.append("file", facture);
  
      const response = await axios.put(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la facture :', error);
      throw error; // relance l'erreur pour la gestion dans le composant si nécessaire
    }
  };

  updateAppliancePict = async (userId, applianceId, file) => {
    const url = `${API_URL}/${userId}/foyers/univers/appliances/${applianceId}/image`;
    console.log('url calling ', url);
    const formData = new FormData();
    formData.append("file", file);
    // Récupérer les headers d'authentification
    const headers = authHeader();
    try {
      const response = await axios.put(url, formData, {headers});
      return response.data;
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'image :", error);
      throw error;
    }
  };

  uploadFacture(formData){
    try {
      const response = axios.post('URL_DE_VOTRE_API', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data);
      // Gérez la réponse de succès ici
    } catch (error) {
      console.error(error);
      // Gérez l'erreur ici
    }
  }
}

// Créez une instance de NotifService
const ApplianceServiceInstance = new ApplianceService();

// Exportez l'instance en tant qu'export par défaut
export default ApplianceServiceInstance;

//export default new ApplianceService();