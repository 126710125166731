import React, { useRef, useEffect, useState} from 'react';

import {Button } from "react-bootstrap"; 

function CameraCapture({ onCapture, onClose }) {
  const videoRef = useRef(null);
  const [streaming, setStreaming] = useState(null); // Ajoutez un état pour stocker le flux

/*
  useImperativeHandle(ref, () => ({
    stopCapture
  }));*/

  useEffect(() => {

    const videoCurrent = videoRef.current;


    navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } })
      .then(stream => {
        window.cameraStream = stream; //important maj global variable pour pouvoir cloturer la caméra à tout moment
        if (videoRef.current) videoRef.current.srcObject = stream;
        setStreaming(stream); // Stockez le flux pour une utilisation ultérieure
      })
      .catch(console.error);

    return () => {
      if (videoCurrent && videoCurrent.srcObject) {
        stopMediaStream(videoCurrent.srcObject); // Appel de stopMediaStream ici
        //videoCurrent.srcObject.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  const stopMediaStream = (stream) => {
    stream.getTracks().forEach(track => track.stop());
  };

  const captureImage = () => {
    const canvas = document.createElement('canvas');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    canvas.getContext('2d').drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

    canvas.toBlob(blob => {
      onCapture(blob);
      if (streaming) {
        stopMediaStream(streaming); // Arrêtez le flux après la capture
      }
    }, 'image/jpeg');
  };

  const closeCamera = () => {
    if (streaming) {
      stopMediaStream(streaming); // Arrêtez le flux après la capture
    }
    onClose();
  };

  return (
    <div>
      <div className= "mt-3" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <video 
        ref={videoRef} 
        autoPlay 
        playsInline
        style={{ height: '200px',alignItems: 'center'  }}>  {/* Adaptation en largeur */}
        </video>
      </div>
      <div  className= "mt-3" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                  <div 
                    className="me-2 text-medium"
                    onClick={closeCamera} // Le clic ici appelle correctement la fonction de fermeture
                    style={{
                      border: '1px solid black',
                      borderRadius: "0.25rem",
                      width: "auto",
                      height: "100%",
                      padding: '0.5rem',
                      cursor: 'pointer' // Indication visuelle que cet élément est cliquable
                    }}>
                    ANNULER
                  </div>
                  <div 
                    className="col text-center text-medium"
                    onClick={captureImage}
                    style={{
                      background: "var(--color-green)",
                      borderRadius: "0.25rem",
                      color: "white",
                      width: "auto",
                      height: "100%",
                      padding: "0.5rem",
                      cursor: 'pointer'
                    }}>
                    ENREGISTRER
                  </div>      
                </div>
    </div>
  );
}

export default CameraCapture