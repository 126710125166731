import React, { useState, useEffect} from "react";
import { Navbar, NavDropdown, Nav, Container, Modal, Badge } from 'react-bootstrap';
import { NavLink, Link, useNavigate,useLocation } from 'react-router-dom';
import AuthService from "../services/auth.service";
import notifServiceInstance from "../services/notif.service";

import EventBus from "../common/EventBus";
import Icon from "./common/Icon";
import "../css/navigation.css";

const Navigation = () => {

  const [currentUser, setCurrentUser] = useState(undefined);
  const [showDropdown, setShowDropdown] = useState(false);
  const [nbNotifications, setNbNotifications] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const [activeItem, setActiveItem] = useState(null); // Suivi de l'élément actif
  const [saveactiveItem, setSaveActiveItem] = useState(null); // Suivi de l'élément actif
  


  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    //si dropdownVisible=true, ca veut dire on veut fermer paramètre , on restitue l'items saved
    if(dropdownVisible) setActiveItem(saveactiveItem);
    //si dropdownVisible=false, on veut ouvrir les paramètres
    else {
      console.log('passage sur paramètre');
      console.log('actifItem : ',activeItem);
      //sauvegarde l'élement actuel
      setSaveActiveItem(activeItem);
      setActiveItem('parametre');
    }
    setDropdownVisible(!dropdownVisible);
  };

  const gotoRoute = (route) => {
    navigate(route);
  };

  // Fonction pour mettre à jour le badge en utilisant le service worker
  const updateBadge = (nbNotif) => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(registration => {
        registration.active.postMessage({
          action: 'updateBadge',
          badgeCount: nbNotif,
        });
      });
    }
  };

  const loadNotifications = async () => {
    try {
      const nbNotif = await notifServiceInstance.getNbNotif();
      updateBadge(nbNotif);
      setNbNotifications(nbNotif);
    } catch (error) {
      console.error('Erreur lors du chargement des notifications :', error);
    }
  };

  const logOut = async () => {
    await AuthService.logout();
    setCurrentUser(undefined);
    navigate("/login");
  };

  const handleToggleModal = () => {
    setShowModal(!showModal);
  };

  const handleMouseEnterDropdown = () => {
    setShowDropdown(true);
  };

  const handleMouseLeaveDropdown = () => {
    setShowDropdown(false);
  };

  useEffect(() => {
   
    const user = AuthService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
      loadNotifications();
    }

    EventBus.on("logout", logOut);

    // Cleanup EventBus listener on unmount
    return () => {
      EventBus.remove("logout");
    };
  }, []); // Empty array ensures effect runs only once when the component mounts

  const nav = [
    { route: "/home", key: "Home", label: "Accueil", icon: "Home.svg" },
    { route: "/notification", key: "Notification", label: "Notification", icon: "Bell.svg" },
    { route: "/inventaire/univers", key: "Inventaire", label: "Inventaire", icon: "Wallet.svg" },
    { route: "/calendar", key: "Calendrier", label: "Calendrier", icon: "Calendar.svg" }
  ];

  const dropdown = [
    { route: "/cgu", key: "CGU", label: "CGU" },
    { route: "/faq", key: "FAQ", label: "FAQ" },
    { route: "/smarfix", key: "SmartFix", label: "SmartFix" }
  ];

  return (
    <div>
        <nav className="mb-4 navbar-expand-md navbar-light d-none d-lg-block sticky-top top-tab-nav" role="navigation">
            <Navbar collapseOnSelect expand="sm">
            <Container>
                <Navbar.Brand href="/">
                <img src={process.env.PUBLIC_URL + '/icons/Logo_dcare.svg'} style={{ width: '100px', height: 'auto', verticalAlign: 'unset'}} alt="DCARE" />
                  </Navbar.Brand>
                <Nav className="ml-auto">
                    {currentUser ? ( 
                        <div className="navbar-nav ml-auto">
                           {nav.map((item) => (
                            <Nav.Item key={item.key} className="nav-link" activeclassname="active">
                               <NavLink to={item.route} className="nav-link" activeclassname="active">{item.label}</NavLink>
                            </Nav.Item>
                            ))}
                            <NavDropdown title="Paramètres" id="nav-dropdown" className="nav-link custom-dropdown" activeclassname="active"
                              show={showDropdown}
                              onMouseEnter={handleMouseEnterDropdown}
                              onMouseLeave={handleMouseLeaveDropdown}>
                              {dropdown.map((item) => (
                                <NavDropdown.Item key={item.key} as={NavLink} to={item.route} className="drop-link" activeclassname="active">
                                  <span>{item.label}</span>
                                </NavDropdown.Item>
                              ))}
                              <NavDropdown.Item as={NavLink} to={"/login"} className="drop-link" activeclassname="active" onClick={logOut}>
                                <span>Déconnecter</span>
                              </NavDropdown.Item>
                            </NavDropdown>
                            
                        </div>
                        ) : (
                        <div className="navbar-nav ml-auto"> 
                             {/* <Nav.Item key='register'>
                                <NavLink to={"/register"} className="nav-link">Sign Up</NavLink>
                            </Nav.Item>   */}
                        </div> 
                        )}
                </Nav>
            </Container>
            </Navbar>
        </nav>
        {/* Bottom Tab Navigator*/} 
          <div>
          <nav className="fixed-bottom navbar-dark d-block d-lg-none bottom-tab-nav" role="navigation">
              <div className=" d-flex flex-row justify-content-around w-100">
                {nav.map((item) => (
                  <Nav.Item className="col" key={item.key}>
                    <NavLink to={item.route} 
                    className={({ isActive }) =>
                      `nav-link bottom-nav-link ${isActive ? 'active' : ''}`}
                    >
                        <div className="row d-flex flex-column justify-content-center align-items-center">
                        <div className="bottom-tab-item">
                        {item.key === "Notification" && nbNotifications > 0 && (
                          <Badge pill className="notification-badge">
                            {nbNotifications}
                          </Badge>
                        )}
                         <Icon src={item.icon} className="icone-nav"/>
                        <br/>
                          {item.label}
                        </div>
                        </div>
                    </NavLink>
                  </Nav.Item>
                ))}
                <Nav.Item className="col" key='option'>
                  <div className={`nav-link bottom-nav-link dropdown-wrapper ${dropdownVisible ? 'active' : ''}`} onClick={toggleDropdown}>
                      <div className="bottom-tab-item">
                        <Icon src="Menu.svg" className="icone-nav"/>
                        <br/>
                          Options
                      </div>
                      {/* Menu déroulant vers le haut */}
                      {dropdownVisible && (
                        <div className="dropdown-menu-reverse " align='right'>
                          {dropdown.map((item) => (
                            <div key={item.route} onClick={() => gotoRoute(item.route)}>
                              <div  className="dropdown-item">
                                {item.label}
                              </div>
                            </div>
                          ))}
                           <div key="Deconnecter" onClick={() => logOut()}>
                              <div  className="dropdown-item">
                                Déconnecter
                              </div>
                            </div>
                        </div>
                      )}
                    </div>
                  </Nav.Item>
              </div>
          </nav>
          </div>  
    </div>
  );
};

export default Navigation;
