import  { Component } from 'react';
/*
import { Navigate } from 'react-router-dom';
import AuthService from "./services/auth.service";
*/

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, redirectToLogin: false};
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Vous pouvez effectuer des actions spécifiques en cas d'erreur ici
    console.error('Erreur capturée:', error, errorInfo);
    //AuthService.logout();
    //window.location.reload();
    // Par exemple, déconnectez l'utilisateur et redirigez-le vers la page de connexion
    //this.setState({ redirectToLogin: true });
  }


  render() {
    /*
    if (this.state.redirectToLogin) {
      // Rediriger vers la page de connexion
    // Rafraîchir la page après un court délai
  
    
    setTimeout(() => {
      window.location.reload();
    }, 100); // Attendre 100 millisecondes avant de rafraîchir
      // Rediriger vers la page de connexion
      return <Navigate to="/home" />;
    }
    */
    return this.props.children;
    //return "";
  }
}

export default ErrorBoundary;
