import React, { useState } from 'react';
import axios from 'axios';

const NewDeviceForm = ({ csrfToken }) => {
  const [currentAppliance, setCurrentAppliance] = useState({
    marque: '',
    modele: '',
    dt_achat: '',
    facture: '',
    note: ''
  });

  // Fonction de validation pour les champs
  const validateField = (field, value) => {
    switch (field) {
      case 'marque':
      case 'modele':
      case 'dt_achat':
      case 'facture':
      case 'note':
        return value.length <= 500; // Limite à 500 caractères pour éviter les attaques DoS
      default:
        return true;
    }
  };

  // Gestion des changements de champ
  const handleFieldChange = (field, value) => {
    if (validateField(field, value)) {
      setCurrentAppliance((prevState) => ({ ...prevState, [field]: value }));
    }
  };

  // Soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        '/api/devices',
        currentAppliance,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken // CSRF token pour protéger contre les attaques CSRF
          }
        }
      );
      alert('Appareil ajouté avec succès');
    } catch (error) {
      console.error('Erreur lors de l\'ajout de l\'appareil:', error);
    }
  };

  return (
    <form className="mt-4 form-container" aria-labelledby="form-title" onSubmit={handleSubmit}>
      
      <div>
        <label className="text-medium"htmlFor="marque">Marque</label>
        <input
          type="text" className="text-small"
          id="marque"
          value={currentAppliance.marque}
          onChange={(e) => handleFieldChange("marque", e.target.value)}
          aria-required="true"
        />
      </div>

      <div>
        <label className="text-medium"htmlFor="modele">Modèle</label>
        <input
          type="text" className="text-small"
          id="modele"
          value={currentAppliance.modele}
          onChange={(e) => handleFieldChange("modele", e.target.value)}
          aria-required="true"
        />
      </div>

      <div>
        <label className="text-medium"htmlFor="dt_achat">Date d'achat</label>
        <input
          type="date" className="text-small"
          id="dt_achat"
          value={currentAppliance.dt_achat}
          onChange={(e) => handleFieldChange("dt_achat", e.target.value)}
          aria-required="true"
        />
      </div>

      <div className="mb-2">
        <label htmlFor="facture" className="text-medium">Facture</label>
        <input
          type="file"
          id="facture"
          accept=".pdf,.jpg,.png"
          onChange={(e) => handleFieldChange("facture", e.target.files[0])}
          aria-describedby="facture-description"
        />
        <div className="text-small" id="facture-description">Téléchargez une facture au format PDF, JPG ou PNG.</div>
      </div>

      <div>
        <label className="text-medium"htmlFor="note">Description</label>
        <textarea
          id="note"
          className="text-small"
          value={currentAppliance.note}
          onChange={(e) => handleFieldChange("note", e.target.value)}
          aria-describedby="note-description"
        />
        <div className="text-small" id="note-description">Veuillez fournir des détails supplémentaires si nécessaire (500 caractères max).</div>
      </div>

      <button className="mt-3  btn btn-primary btn-block" type="submit">Ajouter l'appareil</button>
    </form>
  );
};

export default NewDeviceForm;
