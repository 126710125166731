import React from 'react';

const WaitingModal = () => {
  return (
    <div className="modal-overlay" style={modalOverlayStyle}>
      <div style={modalStyle}>
        <div className="modal-content" style={modalContentStyle}>
          <div className="modal-body" style={modalBodyStyle}>
            <div style={loaderStyle}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Styles inline pour le modal et son contenu
const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const modalStyle = {
  borderRadius: '8px',
  padding: '20px'
};

const modalContentStyle = {
  maxWidth: '400px'
};

const modalBodyStyle = {
  display: 'flex',
  alignItems: 'center'
};

const loaderStyle = {
  border: '8px solid #f3f3f3',
  borderTop: '8px solid var(--color-green)',
  borderRadius: '50%',
  width: '50px',
  height: '50px',
  animation: 'spin 1.5s linear infinite',
};


export default WaitingModal;
