import axios from "axios";
// Dans le fichier où vous avez besoin de l'adresse IP
import config from './config';

const API_URL = `${config.serverIP}/api/auth/`;

class AuthService {
  
  login(username, password) {
    const url=API_URL+ "signin";
    console.log('SERVER_IP:', process.env.SERVER_IP); // Cette ligne affiche la valeur de SERVER_IP dans la console

    console.log("************Trying to authent", url)
    return axios
      .post(url, {
        username,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          console.log("************Trying to authent2 ", API_URL)
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        console.log("************Trying to authent3 ", API_URL)
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username,firstname,lastname, email, adresse, telephone, password) {
    const req = {
      "username":username,
      "firstname":firstname,
      "lastname":lastname,
      "email":email,
      "password":password,
      "adresse": adresse,
      "telephone": telephone,
      "role": ["freemium"]
    }
    console.log('register sent ',req);

    return axios.post(API_URL + "signup",req );
  }

  async updateUser(userId, firstname, lastname, email, adresse, telephone, password) {
    const req = {
      "firstname": firstname,
      "lastname": lastname,
      "email": email,
      "adresse": adresse,
      "telephone": telephone,
      "password": password
    }
  
    console.log('updateUser sent:', req);
    
    // Effectuer une requête PUT vers le backend avec les nouvelles informations utilisateur
    const updatedUserFromBackend = await axios.put(API_URL + `user/${userId}`, req).then(response => {
      return response.data;
    });

    console.log('user updated frombackend ',updatedUserFromBackend);

      // Récupère l'utilisateur du localStorage
    const storedUser = JSON.parse(localStorage.getItem('user'));

    if (storedUser) {
      // Conserve le token et autres informations non mises à jour
      const updatedUser = {
        ...storedUser, // Conserve les propriétés existantes comme token
        firstname: updatedUserFromBackend.firstname,
        lastname: updatedUserFromBackend.lastname,
        email: updatedUserFromBackend.email,
        adresse: updatedUserFromBackend.adresse,
        telephone: updatedUserFromBackend.telephone,
        password: updatedUserFromBackend.password,
      };

      // Met à jour le localStorage avec l'utilisateur mis à jour
      localStorage.setItem('user', JSON.stringify(updatedUser));
    }
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }

  checkFreemium() {
    const user=JSON.parse(localStorage.getItem('user'));
    if (user) return user.roles.includes("ROLE_FREEMIUM");
    return false;
  }
  
  checkAssure() {
    const user=JSON.parse(localStorage.getItem('user'));
    if (user) return user.roles.includes("ROLE_ASSURE");
    return false;
  }
}

// Créez une instance de NotifService
const AuthServiceInstance = new AuthService();

// Exportez l'instance en tant qu'export par défaut
export default AuthServiceInstance;


//export default new AuthService();