import React, {useState,useRef,useMemo,useCallback }  from "react";
import { Modal} from 'react-bootstrap';
import Slider from 'react-slick';

import eventService from "../../services/event.service";

// Modal for Maintenance Steps
const MaintenanceStepModal = ({ show, onClose, maintenanceCard,appliance,handleShowPlanning}) => {
    
  
    const [frequency, setFrequency] = useState('monthly'); // Valeur par défaut
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0); // État pour stocker l'index de la diapositive actuellement affichée
 
    const sliderRef = useRef(null);


    // Utiliser useMemo pour ne pas recalculer les fréquences à chaque rendu
    const frequencies = useMemo(() => [
      { id: 'monthly', value: 'monthly', label: 'Chaque mois' },
      { id: 'threeMonths', value: '3_months', label: '3 mois' },
      { id: 'sixMonths', value: '6_months', label: '6 mois' },
      { id: 'yearly', value: 'yearly', label: 'Chaque année' }
    ], []);
  
    // Optimiser les settings du slider
    const settings = useMemo(() => ({
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      adaptiveHeight: true,
      afterChange: (index) => setCurrentSlideIndex(index),
    }), [setCurrentSlideIndex]);
  
    // Utiliser useCallback pour éviter la re-création des fonctions
    const handlePrevious = useCallback(() => {
      if (currentSlideIndex > 0) {
        setCurrentSlideIndex(currentSlideIndex - 1);
        sliderRef.current.slickPrev();
      }
    }, [currentSlideIndex, setCurrentSlideIndex, sliderRef]);
  
    const handleNext = useCallback(() => {
      if (currentSlideIndex < maintenanceCard.steplist.length) {
        setCurrentSlideIndex(currentSlideIndex + 1);
        sliderRef.current.slickNext();
      }
    }, [currentSlideIndex, setCurrentSlideIndex, maintenanceCard.steplist.length, sliderRef]);
  
    const handleFrequencyChange = useCallback((event) => {
      setFrequency(event.target.value);
    }, [setFrequency]);
  
    
    const handlePeriodPlanning = () => {
        console.log("called Periodplanning")
        const currentDate = new Date();
        if (appliance && appliance.id){
          eventService.saveMaintenancePeriodPlanning(currentDate,frequency,maintenanceCard.title,"entretien récurrent",appliance.id,maintenanceCard.id);
        }else{
        //applianceId inconnu -1
          eventService.saveMaintenancePeriodPlanning(currentDate,frequency,maintenanceCard.title,"entretien récurrent",-1,maintenanceCard.id);
        }
        onClose();
      };

    
    if (!show) {
      return null;
    }
    


    return (
      <Modal show={show} onHide={onClose} centered>
        <Modal.Header closeButton>
          <div className="col d-flex text-center position-relative" style={{ justifyContent: 'center', alignItems: 'center' }}>
  
            {/* Previous Button */}
            {currentSlideIndex>0 && (
            <div
              className="icone-center"
              alt="Previous"
              onClick={handlePrevious}
              style={{ 
                textAlign: 'center', 
                position: 'absolute',
                left: 70,
                cursor: 'pointer' }}
            >
              &lt;
            </div>)}
  
            {/* Current slide number / total slides */}
            <div style={{ fontSize: '1rem', fontWeight: '500' }}> Étape {currentSlideIndex + 1} / {maintenanceCard.steplist.length + 1}</div>
  
            {/* Next Button */}
            {currentSlideIndex<maintenanceCard.steplist.length && (
            <div
              className="icone-center"
              alt="Next"
              onClick={handleNext}  
              style={{ textAlign: 'center', position: 'absolute',
                right: '70px', cursor: 'pointer' }}
            >
              &gt;
            </div>)}
            
          </div>
        </Modal.Header>
        <Modal.Body>
          <Slider ref={sliderRef} {...settings}>
            {maintenanceCard.steplist
              .slice()
              .sort((a, b) => a.steporder - b.steporder)
              .map((step) => (
                <div key={step.id}>
                  <div className="mb-4">
                      <span style={{ fontSize: '1.125rem', fontWeight: '700' }}>{step.title}</span>
                  </div>
                  {step.video && step.video.trim() !== "" && (
                    <div className="mb-4" align="justify">
                      <video width="100%" controls>
                        <source src={step.video} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  )}
                  <div className="mb-4" 
                  style={{ fontSize: '0.75rem', fontWeight: '400'}}
                  dangerouslySetInnerHTML={{ __html: step.detail }}></div>
                </div>
              ))}
            {/* Resume slide */}
            <div className="align-items-center justify-content-center">
              <div className="text-center">
                <h1 className="text-center">Bravo, entretien effectué !</h1>
                <div align="center" className="mt-2 mb-2">
                  <img
                    className="justify-content-center text-center"
                    src={process.env.PUBLIC_URL + '/icons/Happy.svg'}
                    alt="Avatar"
                    style={{width: '10rem', height: 'auto' }}
                    />
                </div>
                
                <p style={{ textAlign: 'center', fontSize: '0.75rem', fontWeight: '400'}}>Félicitations pour avoir terminé l'entretien de votre appareil ! Ce geste éco-responsable contribue non seulement à prolonger la durée de vie de votre équipement, mais aussi à préserver l'environnement.</p>
  
                 
                <div className={`mt-4 mb-4 green-border`}
                style={{ padding:'10px'}}>
                    <span align="center"
                      style={{ textAlign: 'center', fontSize: '0.875rem', fontWeight: '500'}}
                      >Cet entretien est à réaliser tous les {maintenanceCard.frequency} jours, souhaitez-vous le planifier</span>
  
                    {/* Groupe de boutons radio pour les fréquences */}
                    <div className="mt-2 d-flex justify-content-center text-small" style={{ textAlign: 'center'}}>
                      {frequencies.map(({ id, value, label }) => (
                        <div key={id} className="form-check form-check-inline custom-radio">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="frequency"
                            id={id}
                            value={value}
                            checked={frequency === value}
                            onChange={handleFrequencyChange}
                          />
                          <label className="form-check-label" htmlFor={id}>{label}</label>
                        </div>
                      ))}
                    </div>
  
                    <button variant="secondary"
                            className="mt-2 btn btn-primary btn-block mx-auto" onClick={handlePeriodPlanning}
                            style={{ textAlign: 'center', fontSize: '0.75rem', fontWeight: '600', height:'auto' }}>
                                Oui, planifier l'entretien récurrent
                    </button>
                  </div> 
                  
                  <span align="center" style={{ fontSize: '0.875rem', fontWeight: '500'}}>Ou souhaitez-vous le planifier à une autre date ?</span>
                  <div className={`mt-2 mb-4 mx-auto`}>
                    <div style={{ display: 'flex', alignItems : 'center' }}>
                      <div className="me-2" 
                            onClick={onClose}
                            style={{
                              border: '1px solid black',
                              borderRadius:"0.25rem",
                              width: "auto",
                              fontSize:"0.75rem",
                              fontWeight:"600",
                              height: "100%",
                              padding:'5px',
                              }}>RETOUR</div>
                      <div 
                          className="col text-center"
                          style={{
                              background:"var(--color-green)",
                              borderRadius:"0.25rem",
                              color: "white",  
                              textDecoration: "none",
                              width: "auto",
                              height: "100%",
                              padding: "5px",
                              cursor:'pointer'
                              }}>
                            <div 
                              style={{
                                    alignContent:"middle",
                                      color: "white",              
                                      fontSize:"0.75rem",
                                      fontWeight:"600",
                                      textDecoration: "none",
                                      }}
                                    onClick={handleShowPlanning}>PLANIFIER UN ENTRETIEN</div>
                        </div>      
                      </div>
                  </div> 
              </div>
            </div>
          </Slider>
        </Modal.Body>
      </Modal>
    );
  };

  export default MaintenanceStepModal