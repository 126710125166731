import React from 'react';
import Icon  from "./Icon";

const AppBadge = ({ image }) => {
    const url=process.env.PUBLIC_URL +'/icons/'+image;
    console.log("url bagde : ",url);
    return (
        <div style={styles.roundContainer}>
            <div style={styles.circle}>
                <Icon 
                    src={image} 
                    alt="Contenu" 
                    style={styles.image} />
            </div>
        </div>
    );
};

const styles = {
    roundContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100px', // Taille du conteneur du rond
    },
    circle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '70px', // Diamètre du rond
        height: '70px', // Diamètre du rond
        borderRadius: '50%', // Rend le div un cercle
        backgroundColor: 'lightgray', // Couleur du fond du cercle
    },
    image: {
        width: '70%', // Utilisez 100% pour s'assurer que l'image s'adapte au cercle
        height: '70%', // Utilisez 100% pour s'assurer que l'image s'adapte au cercle
        objectFit: 'cover', // Assure que l'image couvre le cercle, sans déformation
        
    },
};

export default AppBadge;
