import React from 'react';

const Stepper = ({ steps, currentStep }) => {
  return (
    <div style={{ width: '100%', maxWidth: '800px', margin: '0 auto' }}>
      {/* Première ligne : Cercles et lignes de connexion */}
      <div style={{ display: 'flex', alignItems: 'center', height: '30px' }}>
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            {/* Colonne pour chaque étape avec le cercle ou le checkmark */}
            <div style={{
              width: '30px', // Largeur du rond
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <div style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                border: `2px solid ${index <= currentStep ? 'var(--color-green)' : '#ccc'}`,
                backgroundColor: index < currentStep ? 'var(--color-green)' : 'transparent',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white', // Couleur du checkmark
                fontSize: '9px', // Taille du checkmark
              }}>
                {index < currentStep ? '✔' : (
                  index === currentStep ? (
                    <div style={{
                      width: '4px', // Petit cercle au centre
                      height: '4px',
                      borderRadius: '50%',
                      backgroundColor: 'var(--color-green)'
                    }}></div>
                  ) : (
                    <div style={{
                      width: '4px',
                      height: '4px',
                      borderRadius: '50%',
                      backgroundColor: '#ccc'
                    }}></div>
                  )
                )}
              </div>
            </div>

            {/* Colonne pour la ligne de connexion (sauf après le dernier cercle) */}
            {index < steps.length - 1 && (
              <div style={{
                flex: 1, // La ligne prend tout l'espace entre les étapes
                height: '2px',
                backgroundColor: index < currentStep ? 'var(--color-green)' : '#ccc',
              }}></div>
            )}
          </React.Fragment>
        ))}
      </div>

      {/* Deuxième ligne : Noms des étapes, alignés sous chaque cercle */}
      <div className="text-small" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '4px' }}>
        {steps.map((step, index) => (
          <div key={index} style={{
            flex: 1, // Divisé également entre chaque étape
            textAlign: index === 0 ? 'left' : index === steps.length - 1 ? 'right' : 'center', // Aligne le premier à gauche, le dernier à droite
            color: '#777',
          }}>
            {step}
          </div>
        ))}
      </div>
    </div>
  );
};



export default Stepper;
