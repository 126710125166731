import React, { useEffect, useState,useRef, useMemo, useCallback }  from "react";
import { Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/entretienproduct.css";
import { useLocation, useNavigate} from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import getLabel from "../../services/glossary.service";

import MaintenanceStepModal from "./maintenancestep";
import PlannerModal from "../common/Planner";

import { PageTitle } from "../common/Header";


// Modal Component
const AgentInfoModal = ({ show, onClose}) => {
  if (!show) {
    return null;
  }

  return (
    <div style={{
      position: 'fixed', top: 0, left: 0, width: '100%', height: '100%',
      display: 'flex', justifyContent: 'center', alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,0.5)'
    }}>
      <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', width: '300px', textAlign: 'center' }}>
        <h4 style={{ fontSize: '0.875rem', fontWeight: '500' }}>Mes produits d'entretien</h4>
        <p style={{ fontSize: '0.75rem', fontWeight: '400' }}>Pour obtenir les meilleurs résultats, il est fortement recommandé d'utiliser les produits listés. En cas de produits manquants, pas de panique, je peux facilement les procurer.</p>
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems : 'center' }}>
            <div className="ms-1 me-2" 
                  onClick={onClose}
                  style={{
                    border: '1px solid black',
                    borderRadius:"0.25rem",
                    width: "auto",
                    fontSize:"12px",
                    fontWeight:"500",
                    height: "100%",
                    padding:'5px'
                    }}>ANNULER</div>
            <div 
                className="col-6 text-center"
                style={{
                    background:"var(--color-green)",
                    borderRadius:"0.25rem",
                    color: "white",  
                    textDecoration: "none",
                    width: "auto",
                    height: "100%",
                    padding: "5px"
                    }}>
                  <a 
                    style={{
                           alignContent:"middle",
                            color: "white",              
                            fontSize:"12px",
                            fontWeight:"500",
                            textDecoration: "none",
                            }} href="/#/calendar">COMMANDER LES PRODUITS</a>
              </div>      
        </div>
      </div>
    </div>
  );
};


const MaintenanceCardDetail = () => {
  const location = useLocation();
  const { state } = location;
  const { maintenanceCard, appliance } = state;
  const navigate = useNavigate();

  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showModalPlanning, setShowModalPlanning] = useState(false);
  
  const handleClose = useCallback(() => {
    setShowInfoModal(false);
    setShowModal(false);
    setShowModalPlanning(false);
  }, []);
  
  const handleShow = useCallback(() => {
    setShowInfoModal(false);
    setShowModal(true);
    setShowModalPlanning(false);
  }, []);

  const handleInfoShow = useCallback(() => {
    setShowInfoModal(true);
    setShowModal(false);
    setShowModalPlanning(false);
  }, []);
  
  const handleShowPlanning = useCallback(() => {
    setShowInfoModal(false);
    setShowModal(false);
    setShowModalPlanning(true);
  }, []);
  

  

  if (!state || !state.maintenanceCard) {
    // Gérer le cas où state ou maintenanceCard n'est pas défini
    return <div>Erreur : Aucune donnée de maintenanceCard trouvée</div>;
  }
  

  console.log('Carte reçue:', maintenanceCard);
  // Supposons que maintenanceCard.pict contient la chaîne d'URLs séparées par des points-virgules
  //const imageLinks = maintenanceCard.pict.split(';');

  return (
    <div className="mb-4 mt-4">
        <PageTitle title={`Fiche d'entretien`}/>
        <div >
          <div className="mb-4" style={{ width:'100%', fontSize: '1rem', fontWeight: '600' }}>{maintenanceCard.title} {appliance.name}</div>
          
          <div>
              <span style={{ fontSize: '0.875rem', fontWeight: '500' }}>Information</span>
                  <div className={`mt-2 mb-3 ${isLargeScreen ? '' : ''}` }>
                    <div className="fiche align-items-center pt-2 pb-2 px-3 h-100"
                    style={{ fontSize: '0.75rem', fontWeight: '400' }}>
                        <div className="mb-2 col d-flex">
                              <span className="mr-2">Durée :</span>
                              <span style={{fontWeight: '500' }}>&nbsp;{maintenanceCard.duration} minutes</span>
                            </div>
                            <div className="mb-2 col d-flex">
                                  <span>Fréquence :</span>
                                  <span style={{fontWeight: '500' }}>&nbsp;{maintenanceCard.frequency} jours</span>
                              </div>
                              <div className="col d-flex">
                                  <span>Difficulté :</span>
                                  <span style={{fontWeight: '500' }}>&nbsp;{getLabel(maintenanceCard.level)}</span>
                              </div>
                      </div>
                    </div>
              </div>
                  
              <div className='row'>
                <div className={`d-flex justify-content-between align-items-end mt-3` }>
                  <div style={{ fontSize: '0.875rem', fontWeight: '500' }}>Produits nécessaires</div>
                  <div 
                    style={{ fontSize: '0.875rem', 
                      fontWeight: '400',
                      border: '1px solid black',  // Ajoute une bordure
                      borderRadius: '50%',        // Rend la bordure circulaire
                      width: '1rem',            // Largeur du cercle
                      height: '1rem',           // Hauteur du cercle
                      display: 'flex',            // Utilisation de flexbox pour centrer le contenu
                      justifyContent: 'center',   // Centre horizontalement
                      alignItems: 'center',       // Centre verticalement
                      lineHeight: '1rem',         // Ajuste la hauteur de ligne pour centrer le texte
                      textAlign: 'center',         // Centre le texte dans le div
                      cursor: 'pointer',
                      }}
                      onClick={handleInfoShow}>!</div>
                </div>
                <div style={{ fontSize: '0.75rem', fontWeight: '500' }}>{maintenanceCard.agentlist.length} produits</div>
              </div> 

              <div className="mt-2 d-flex row gx-3">
                {maintenanceCard.agentlist.map((agent) => (
                  <div key={agent.id} className="col-4 col-md-4 col-lg-3 product-link" >
                    <div className="d-flex flex-column justify-content-between align-items-center h-100" style={{ padding:'5px', border: '1px solid var(--color-green)'}}>
                    <img style={{ with: '6rem', height: '6rem' }} src={process.env.PUBLIC_URL + '/icons/image/'+agent.pict} alt="" className="img-fluid mb-2"/> 
                      <div  className="text-center" style={{fontSize: '0.75rem', fontWeight: '500', wordWrap: 'break-word' }}>
                        {agent.name}
                      </div>
                      {/* On s'assure que cet élément reste aligné en bas */}
                      <div className="mt-auto" style={{ fontSize: '0.75rem', fontWeight: '400' }}>
                        {agent.volume}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="mt-4 mb-4" style={{ fontSize: '0.875rem', fontWeight: '500' }}>J'ai tous les produits nécessaires. Je peux procéder à l'entretien !</div>

              <div className={`align-items-center justify-content-center`}>
                <button 
                        className="btn btn-primary btn-block mx-auto" onClick={handleShow}>
                            Commencer
                </button>
                <button 
                        className="mt-3 btn btn-secondary btn-block mx-auto" onClick={handleShowPlanning}>
                            Planifier cet entretien
                </button>
              </div> 

              
              {/* Render Logout Confirmation Modal */}
              {showInfoModal && <AgentInfoModal show={showInfoModal} onClose={handleClose} />}
              
              {/* Render Maintenance Step Modal */}
              {showModal && (
                <MaintenanceStepModal
                  show={showModal}
                  onClose={handleClose}
                  maintenanceCard={maintenanceCard}
                  appliance={appliance}
                  handleShowPlanning={handleShowPlanning}
                />
              )}

              {/* popin maintenance planner*/}
              {showModalPlanning && (
                <PlannerModal
                  showModalPlanning={showModalPlanning}
                  handleClose={handleClose}
                  maintenanceCard={maintenanceCard}
                  appliance={appliance}
                />
              )}
          </div>
      </div>
  );
};

export default MaintenanceCardDetail;