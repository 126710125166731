import axios from "axios";

import authHeader from './auth-header';
// Dans le fichier où vous avez besoin de l'adresse IP
import config from './config';

const API_URL = `${config.serverIP}/${config.apiVersion}`;

class NotifService{

  getNbNotif() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user || !user.id) {
      console.error('Utilisateur non trouvé dans le localStorage');
      return Promise.reject('Utilisateur non trouvé');
    }
  
    const userId = user.id;
    const url = `${API_URL}/profils/users/${userId}/notifs/count`;
  
    const headers = authHeader();
  
    // Effectuer la requête GET avec Axios
    return axios.get(url, { headers })
      .then(response => {
        console.log('nb notification = ', response.data)
        // Traitez la réponse ici si besoin
        return response.data;
      })
      .catch(error => {
        // Gérer l'erreur ici
        console.error('Erreur lors de la récupération du nombre de notifications:', error);
        throw error;
      });
  }
  

  getNotif() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user || !user.id) {
      console.error('Utilisateur non trouvé dans le localStorage');
      return Promise.reject('Utilisateur non trouvé');
    }
  
    const userId = user.id;
    const url = `${API_URL}/profils/users/${userId}/notifs`;
  
    const headers = authHeader();
  
    // Effectuer la requête GET avec Axios
    return axios.get(url, { headers })
      .then(response => {
        console.log('les notifications = ', response.data)
        // Traitez la réponse ici si nécessaire
        return response.data;
      })
      .catch(error => {
        // Gérer l'erreur ici
        console.error('Erreur lors de la récupération des notifications:', error);
        throw error;
      });
  }
  


  async readNotif(notifId) {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user || !user.id) {
        console.error('Utilisateur non trouvé dans le localStorage');
        return Promise.reject('Utilisateur non trouvé');
      }
  
      const userId = user.id;
      const url = `${API_URL}/profils/users/${userId}/notifs/${notifId}`;
  
      const headers = authHeader();  // Ajoutez vos headers d'authentification ici
      
      // Corps de la requête pour marquer la notification comme lue
      const requestBody = {
        status: 'read',  // Exemple de champ pour mettre à jour le statut
      };

      // Effectuer une requête PUT pour marquer la notification comme lue
      const updatedNotifFromBackend = await axios.put(url, requestBody, { headers })
        .then(response => {
          return response.data;
        });
  
      console.log('Notification mise à jour depuis le backend:', updatedNotifFromBackend);
      return updatedNotifFromBackend;
  
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la notification:', error);
      throw error;
    }
  }
  
    
}
// Créez une instance de NotifService
const notifServiceInstance = new NotifService();

// Exportez l'instance en tant qu'export par défaut
export default notifServiceInstance;