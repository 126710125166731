import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/entretienproduct.css";
import EventBus from "../../common/EventBus";
import {  Button } from "react-bootstrap"; 
import ApplianceService from "../../services/appliance.service";
import AuthService from "../../services/auth.service";
import { useNavigate,useLocation} from 'react-router-dom';


const ApplianceList = () => {
   const [productList, setProductList] = useState([]);
   const location = useLocation();
    const { state } = location|| {}; // S'assurer que state est un objet si location.state est null;
  
   const navigate = useNavigate();
   const { tosecond} = state|| {}; // S'assurer que state n'est pas null avant de déstructurer

  

  const handleClicktoDetail = (appliance) => {
    if (tosecond) navigate(`/secondlife/actionselect`, { state: { product: appliance } });
     else navigate(`/inventaire/fichedetail`, { state: { appliance } });
  };

  const handleClicktoAdd = (appliance) => {
    const toadd=true;
    navigate(`/productlist`,{ state: { toadd } });
  };

  useEffect(() => {
    console.log('Dans inventaire des produits');
    document.body.style.backgroundColor = '#ffffff';

    const user = AuthService.getCurrentUser();
    console.log('User reçue:', user);
    

    console.log('Dans inventaire des produits');
   

    ApplianceService.getApplianceByUser(user.id)
      .then((response) => {
        setProductList(response.data);
      })
      .catch((error) => {
        console.error('Une erreur s\'est produite:', error);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      });
    

    
  }, []); // Le tableau vide en second paramètre signifie que useEffect s'exécutera une fois après le montage initial

  
  return (
    <div className="mb-4 mt-4">
      <div className="justify-content-center mb-4"> 
              <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <button onClick={() => navigate(-1)} style={{ position: 'absolute', left: '0', border: 'none', background: 'none', cursor: 'pointer', fontSize: '24px' }}>
                            &lt;
                          </button>
                          <h3 style={{ margin: '0', textAlign: 'center', fontSize: '1.25rem', fontWeight: '600' }}>Mon inventaire</h3>
              </div>
                <div className="product-container justify-content-center mt-4"> 
                    <div className="d-flex row gx-3 mb-3"> 
                    {productList.map((product, index) => (
                        <div className="col-6 col-md-3 col-lg-3 text-center mb-3 pe-2" 
                            key={index}
                            onClick={() => handleClicktoDetail(product)}
                            style={{ cursor: 'pointer' }}>
                            <div className="product-background mb-1"
                            style={{ height : "100%"}}>
                                <img 
                                    src={product.pict} 
                                    alt={product.prod_name} 
                                    className="img-fluid mb-2" 
                                />
                                <div style={{ fontSize: '0.875rem', fontWeight: '600' }}>{product.prod_name}</div>
                                <div style={{ fontSize: '0.75rem', fontWeight: '400' }}>{product.name}</div>
                            </div>
                        </div>
                    ))}
                    </div>
                    <div className="col-12 col-md-4 col-lg-4 mb-4" onClick={() => handleClicktoAdd()}> 
                        <Button variant="primary">Ajouter un appareil</Button>
                    </div>
                  </div>
                </div>
        </div>
  );
};

export default ApplianceList;