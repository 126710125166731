import React, {useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/entretienproduct.css";

import { Button } from "react-bootstrap"; 
import { useNavigate,useLocation } from 'react-router-dom';

import Navigation from "../navigation";
import Header from "../common/Header";

const ActionSelect = () => {
    const location = useLocation();
    const { state } = location|| {}; // S'assurer que state est un objet si location.state est null;
    const { product } = state|| {}; // S'assurer que state n'est pas null avant de déstructurer
    console.log("appareil reçu ",product);

   const navigate = useNavigate();
  
  useEffect(() => {
    console.log('Dans inventaire des produits');
  }, []); // Le tableau vide en second paramètre signifie que useEffect s'exécutera une fois après le montage initial

  const handleClicktoGive = () => {
    // Rediriger vers la page CardDetail et passer l'objet maintenanceCard
    navigate(`/secondlife/give`,{ state: { product } });
  };

  const handleClicktoSell = () => {
    // Rediriger vers la page CardDetail et passer l'objet maintenanceCard
    navigate(`/secondlife/sell`,{ state: { product } });
  };

  const handleClicktoRecycle = () => {
    // Rediriger vers la page CardDetail et passer l'objet maintenanceCard
    navigate(`/secondlife/recycle`,{ state: { product } });
  };
  
  return (
    <div className="mb-4">
      <Header/>  
        <div className="container mt-4">
            <Navigation/>
            <div className="justify-content-center mb-4"> 
                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <button onClick={() => navigate(-1)} style={{ position: 'absolute', left: '0', border: 'none', background: 'none', cursor: 'pointer', fontSize: '24px' }}>
                              &lt;
                            </button>
                            <h3 style={{ margin: '0', textAlign: 'center', fontSize: '1.25rem', fontWeight: '600' }}>Deuxième vie : {product.prod_name} </h3>
                </div>
                <div className="col-12 mt-4 mb-4">
                    <span style={{
                                    fontSize: '0.875rem',
                                    fontWeight: '400',
                                }}>Envisagez de donner votre appareil via une association agréée pour maximiser son impact positif. Ce geste écologique non seulement aide ceux qui en ont besoin, mais contribue également à la préservation de notre environnement.</span>
                    <div className="col-12 col-lg-6"> 
                        <Button 
                            className="mt-2"
                            onClick={() => handleClicktoGive(product)}
                        >Je donne</Button>
                    </div>
                </div>
                <div className="col-12 mt-4 mb-4"> 
                    <span style={{
                                    fontSize: '0.875rem',
                                    fontWeight: '400',
                                }}>Revendez votre appareil pour un double bénéfice : économique pour vous et écologique pour la planète. Cela permet de prolonger la vie utile de l'appareil et de réduire les déchets, contribuant ainsi à un environnement plus sain.</span>
                    <div className="col-12 col-lg-6"> 
                        <Button 
                            className="mt-2"
                            onClick={() => handleClicktoSell(product)}
                        >Je vends</Button>
                    </div>
                </div>
                <div className="col-12 mt-4 mb-4"> 
                    <span style={{
                                    fontSize: '0.875rem',
                                    fontWeight: '400',
                                }}>Le recyclage joue un rôle crucial dans la protection de notre environnement en réduisant la quantité de déchets, en économisant les ressources naturelles et en diminuant la pollution, contribuant ainsi à un avenir plus durable.</span>
                    <div className="col-12 col-lg-6"> 
                        <Button 
                            className="mt-2"
                            onClick={() => handleClicktoRecycle(product)}
                        >Je recycle</Button>
                    </div>
                </div>
              </div>
        </div>
    </div>
  );
};

export default ActionSelect;