import React, { useState}   from "react";
import { Modal} from 'react-bootstrap';

import eventService from "../../services/event.service";

import Calendar from 'react-calendar';
import "../../css/calendar.css";

// Modal for Maintenance Steps
const PlannerModal = ({ showModalPlanning,handleClose,maintenanceCard,appliance}) => {
   
    const [textInput, setTextInput] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date()); // Initialisation de la date sélectionnée

  
    const handleInputChange = (e) => {
        setTextInput(e.target.value);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date); // Mise à jour de la date sélectionnée
    };

    const handleSaveEvent = (dt_planned,appliance,maintenanceCard) => {
        const currentDate = new Date();
        if (appliance && appliance.id){
          eventService.saveMaintenanceEvent(currentDate,dt_planned,maintenanceCard.title,textInput,appliance.id,maintenanceCard.id);
        }else{
        //applianceId inconnu -1
          eventService.saveMaintenanceEvent(currentDate,dt_planned,maintenanceCard.title,textInput,-1,maintenanceCard.id);
        }
        handleClose();
      };

      if (!showModalPlanning) {
        return null;
      }

    return (
    <Modal show={showModalPlanning} onHide={handleClose} className="modal-maintenance px-2" activeclassname="active">
        <Modal.Header closeButton>
            <div className="text-center">
            <h1 style={{ fontSize: '1.25rem', fontWeight: '600' }}>Planifier mon entretien</h1>
            </div>
        </Modal.Header>
        <Modal.Body>
        <div className="row">

        <div className={`col-12 col-md-6 col-lg-4 mb-3 `} > 
        <div className="mb-4 mb-4" style={{ width:'100%', fontSize: '1.125rem', fontWeight: '600' }}>{maintenanceCard.title} {appliance.name}</div>              
        <span style={{ fontSize: '0.875rem', fontWeight: '500' }}>Sélectionnez une date</span>
            <Calendar className="mt-3"
            onChange={handleDateChange}
            value={selectedDate}
            locale="fr-FR" // Utilisez la notation BCP 47 pour la langue française
            />
        </div>
        <div className={`col-12 col-md-6 col-lg-6 mb-3`} > 
            {selectedDate && (
            <div>
                <span style={{ fontSize: '0.875rem', fontWeight: '500' }}>Date sélectionnée :</span>
                <p  className="mt-2 " style={{ fontSize: '0.75rem', fontWeight: '400' }}>{`${selectedDate.getDate()} - ${selectedDate.toLocaleDateString('fr-FR', { month: 'long' })} - ${selectedDate.getFullYear()}`}</p>
                <div>
                    <span  className="mt-2 mb-2" style={{ fontSize: '0.875rem', fontWeight: '500', display: 'block' }}>Ajoutez une note</span>
                    <textarea
                    className="editable-content mb-2 mt-0"
                    style={{
                        width: '100%',
                        minHeight: '100px',
                        padding: '8px',
                        border: '1px solid var(--color-secondary)',
                        borderRadius: '4px',
                        direction: 'ltr'
                    }}
                    value={textInput}
                    onChange={handleInputChange}
                />
                    </div>
                <div  className="col mt-2">
                <button variant="secondary"
                    className="btn btn-primary btn-block px-auto" onClick={() => handleSaveEvent(selectedDate,appliance,maintenanceCard)}>
                    Valider
                </button>
                </div>
                </div>
            )}
            </div>
        </div>
            <br/> <br/> <br/>
        </Modal.Body>
    </Modal> 
    );
};

export default PlannerModal