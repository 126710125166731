import React, { useState, memo} from 'react';
import CameraCapture from './CameraCapture'; // Assurez-vous que le chemin d'importation est correct

import {Button } from "react-bootstrap"; 
const ImageUploader = ({ uploadImageFunction, onImageUpload }) => {
  
  const [selectedImage, setSelectedImage] = useState(null);
  const [urlSelectedImage, setURLSelectedImage] = useState(null);
  const [useCamera, setUseCamera] = useState(false);
  const fileInputRef = React.createRef(); 


  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const url = URL.createObjectURL(file)
      setSelectedImage(file); // Met à jour l'image sélectionnée
      setURLSelectedImage(url);
      onImageUpload(url, file); // Appeler onImageUpload après la mise à jour
    }
  };

  const handleUseCamera = (e) => {
    setUseCamera(true)
    setSelectedImage(null);
    setURLSelectedImage(null);
    onImageUpload(null,null);
  };

  const handleCapture = (blob) => {
    const imgFile = new File([blob], "captured_image.jpg", { type: "image/jpeg", lastModified: new Date() });
    setSelectedImage(imgFile);
    console.log("image captured ", imgFile);
    const url = URL.createObjectURL(imgFile)
    onImageUpload(url,imgFile ); // Appeler onImageUpload après la mise à jour
    setUseCamera(false); // Cachez la caméra après la capture
  };

  const closeCapture = () => {
    setUseCamera(false)
  };


  return (
    <div className="mt-3 mb-3 text-small">
        {useCamera ? (
            <CameraCapture onCapture={handleCapture} onClose={closeCapture}/>
        ) : (
            <form>
                <div>

                {urlSelectedImage && (
                  <div className="mb-3" style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={urlSelectedImage} alt="image" style={{ width: 'auto', height: '200px', margin: '0 auto' }} />
                  </div>
                )}
          
                <input 
                        type="file" 
                        ref={fileInputRef}
                        onChange={handleImageChange} 
                        accept="image/*" 
                        style={{ display: 'none' }}  // Masquer l'input file
                        />
               
                <div className="mt-3 mb-2" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                        {/* Bouton personnalisé pour choisir un fichier */}
                    <Button 
                      className="col btn btn-secondary btn-block me-2"  
                      style={{ fontSize: '0.75rem', fontWeight: '500', padding: '0.25rem', height: '1.75rem' }}
                      onClick={() => fileInputRef.current.click()}  // Déclenche le clic sur l'input file caché
                    >
                      Choisir image
                    </Button>
                    <Button variant="secondary" 
                                className='col' 
                                style={{ fontSize: '0.75rem', fontWeight: '500', padding: '0.25rem',height: '1.75rem'  }}
                                onClick={handleUseCamera}>Avec Caméra</Button>
                </div>
                </div>
                
            </form>
        )}
    </div>
  );
}

export default ImageUploader;
